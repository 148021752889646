.grouprecord-certificates-listing-items {

  margin: 0 auto;
  padding: 50px 0;
  width: 80%;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;

}

.grouprecord-certificates-listing-item {

  margin: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;

}

.grouprecord-certificates-listing-item-inner {

  margin: 0;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
  border-radius: 6px;
  text-decoration: none;
  position: relative;

}

.grouprecord-certificates-listing-item-inner:hover {

  opacity: 0.8;

}

.grouprecord-certificates-listing-item-inner-action {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  box-sizing: border-box;
  background-color: #7F3584;

}

.grouprecord-certificates-listing-item-inner-action p {

  margin: 21px auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;

}

.grouprecord-certificates-listing-item-heading {

  margin: 0;
  margin-bottom: 8.5px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #222;

}

.grouprecord-certificates-listing-item-slogan {

  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  text-decoration: none;
  color: #777;

}