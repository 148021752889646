.groupimport {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupimport-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.groupimport-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;

}

.groupimport .details-box {

  margin-top: -250px;

}

.groupimport-content-img {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  max-height: 256px;

}

.groupimport-content {

  margin: 0;
  padding: 15px 0;
  padding-bottom: 55px;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.groupimport-content-heading {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  max-width: 65%;
  text-align: center;
  font-size: 21px;
  line-height: 1.5;
  text-transform: uppercase;

}

.groupimport-content-slogan {

  margin: 15px auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  max-width: 65%;
  text-align: center;
  font-size: 13px;
  line-height: 1.5;

}

.groupimport-content-subtext {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  max-width: 45%;
  text-align: center;
  font-size: 11px;
  line-height: 1.5;
  color: #111;

}

.groupimport-schema-fields {

  margin: 0 auto;
  margin-top: 20px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 70%;
  border: 1px solid white;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;

}

.groupimport-schema-field {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
  width: 100%;

}

.groupimport-schema-field-last {

  border-bottom: 0;

}

.groupimport-schema-field-meta {

  margin: 0;
  padding: 5px;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 60%;
  float: left;

}

.groupimport-schema-field-meta > p {

  margin: 0;
  padding: 5px 10px;
  padding-top: 7px;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 15px;

}

.groupimport-schema-field-type {

  margin: 0;
  padding: 5px;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 40%;
  float: left;

}

.groupimport-schema-field-type > select {

  margin: 0;
  padding: 5px;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;

}

.groupimport-cta {

  margin: 25px auto;
  padding: 10px 20px;
  display: table;
  position: relative;
  box-sizing: border-box;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #7F3584;
  color: white;
  border: 1px solid white;
  overflow: hidden;
  border-radius: 6px;

}

.groupimport-cta:hover {

  cursor: pointer;
  background-color: white;
  color: #7F3584;
  border-color: #7F3584;

}

@media(max-width: 800px) {

  .groupimport-content-subtext {

    max-width: 70%;

  }

}