.capture {

    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #FAFAFA;
  
  }
  
  .capture-inner {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-hero {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    box-sizing: border-box;
    background-color: #7F3584;
  }

  .capture-hero-home {
    padding: 50px;
  }
  
  .capture-hero-logo {
  
    margin: 0;
    padding: 0;
    float: right;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-hero-logo img {
  
    margin: 0;
    margin-top: -50px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    height: 196px;
  
  }
  
  .capture-hero-intro {
  
    margin: 0 25px;
    padding: 25px 0;
    box-sizing: border-box;
    display: block;
    float: left;
    width: 70%;
  
  }
  
  .capture-hero-intro-text {
  
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
    font-size: 21px;
    color: white;
  
  }
  
  .capture-header-bg {
  
    margin: 0;
    padding: 0;
    margin-top: -180px;
    margin-bottom: -58px;
    box-sizing: border-box;
    fill: white;
    float: left;
    z-index: 1000;
  
  }
  
  .capture-upload-header {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-upload-header img {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
  
  }
  
  .capture-upload-item {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-upload-item-preview {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 40%;
  
  }
  
  .capture-upload-item-preview img {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
  
  }
  
  .capture-upload-item-meta {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 60%;
  
  }
  
  .capture-heading {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-nav-action-items {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    display: none;
  
  }
  
  .capture-nav-section {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
  
  }
  
  .capture-nav-action-left, .capture-nav-action-right {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    width: 20%;
    text-decoration: none;
    color: #666;
  
  }
  
  .capture-nav-country {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    width: 60%;
    cursor: pointer;
  
  }
  
  .capture-nav-action-flag {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 2px;
  
  }
  
  .capture-nav-action-flag img {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    height: 32px;
  
  }
  
  .capture-nav-action-label {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    text-align: center;
  
  }
  
  .capture-hero-cta {
  
    margin: 0;
    margin-top: 20px;
    padding: 15px 25px;
    display: block;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 6px;
    float: left;
    background-color: white;
    text-decoration: none;
    color: #7F3584;
    font-size: 24px;
    cursor: pointer;
  
  }
  
  .capture-hero-cta:hover {
  
    background-color: #7F3584;
    color: white;
  
  }
  
  .capture-section .wrappingoverlay {
    position: relative;
  }
  
  .capture-section {
  
    margin: 0;
    padding: 15px 0;
    display: block;
    box-sizing: border-box;
    position: relative;
  
  }
  
  .capture-section-chipndoodle {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #7f3584;
  
  }
  
  .capture-section-tools {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #2b2d42;
  
  }
  
  .capture-section-petticash {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #F38437;
  
  }
  
  .capture-section-about {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #e8eddf;
  
  }
  
  .capture-section-developer {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: #cfdbd5;
  
  }
  
  .capture-section-column {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 50%;
  
  }
  
  .capture-section-developer-meta {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 50%;
  
  }
  
  .capture-section-chipndoodle .capture-section-column {
  
    padding: 50px 0;
  
  }
  
  .capture-section-chipndoodle-heading {
  
    margin: 0;
    margin-top: 75px;
    margin-bottom: 15px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: white;
    font-size: 26px;
  
  }
  
  .capture-section-chipndoodle-slogan {
  
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: white;
  
  }
  
  .capture-section-chipndoodle-link {
  
    margin: 0;
    margin-left: 60px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 15px;
    display: block;
    box-sizing: border-box;
    width: 50%;
    border: 1px solid white;
    border-radius: 6px;
  
  }
  
  .capture-section-chipndoodle-link-meta {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 50%;
  
  }
  
  .capture-section-chipndoodle-logo {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    height: 46px;
    float: left;
  
  }
  
  .capture-section-chipndoodle-logo-heading {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    color: white;
  
  }
  
  .capture-section-chipndoodle-text {
  
    margin: 0 auto;
    padding: 0;
    padding-left: 60px;
    display: block;
    box-sizing: border-box;
    color: white;
    float: left;
    font-size: 11px;
    line-height: 1.4;
  
  }
  
  .capture-section-chipndoodle-logo-desc {
  
    margin: 0 auto;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: white;
    float: left;
    font-size: 11px;
    line-height: 1.4;
  
  }
  
  .capture-section-chipndoodle-search {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    position: relative;
    width: 75%;
  
  }
  
  .capture-section-chipndoodle-search input {
  
    margin: 0;
    padding: 10px 15px;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 60%;
    border: 0;
    font-size: 21px;
      
  }
  
  .capture-section-chipndoodle-search button {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    border: 0;
    width: 20%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 21px;
    background-color: white;
    color: #7f3584;
      
  }
  
  .capture-section-chipndoodle-search button:hover {
  
    background-color: #7f3584;
    color: white;
    cursor: pointer;
  
  }
  
  .capture-section-chipndoodle-underline {
  
    margin: 0;
    margin-top: 15px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: white;
    font-size: 14px;
  
  }
  
  .capture-section-chipndoodle-providers {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
  }
  
  .capture-section-chipndoodle-providers img {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    height: 46px;
  
  }

  .capture-microchip-search-banner-heading {

    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: white;
    text-align: center;

  }
  
  .capture-microchip-search {
  
    margin: 50px auto;
    margin-top: 25px;
    padding: 0;
    display: table;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    position: relative;
  
  }

  .capture-microchip-search-center {

    width: 60%;

  }
  
  .capture-microchip-search input {
  
    margin: 0;
    padding: 10px 15px;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 85%;
    border: 0;
    border-right: 1px solid #7f3584;
    font-size: 21px;
    color: #111;
      
  }
  
  .capture-microchip-search button {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    border: 0;
    width: 15%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 21px;
    background-color: #7f3584;
    color: white;
      
  }
  
  .capture-section-chipndoodle-search-submit {
  
    right: 20% !important;
      
  }
  
  .capture-microchip-search button:hover {
  
    background-color: darkgray;
    color: white;
    cursor: pointer;
  
  }
  
  .capture-microchip-cta-button {
  
    margin: 0 auto;
    margin-top: 50px;
    padding: 15px 25px;
    display: table;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 6px;
    text-decoration: none;
    background-color: #7F3584;
    color: white;
    font-size: 24px;
    cursor: pointer;
    text-transform: uppercase;
  
  }
  
  .capture-microchip-cta-button:hover {
  
    background-color: white;
    color: #7F3584;
  
  }
  
  .capture-microchip-cta-button-alt {
  
    background-color: white;
    color: #7F3584;
  
  }
  
  .capture-microchip-cta-button-alt {
  
  
    background-color: #7F3584;
    color: white;
    font-size: 16px;
  
  }
  
  .capture-microchip-take-image {
  
    margin: 0 auto;
    padding: 15px;
    display: block;
    box-sizing: border-box;
    position: relative;
    width: 30%;
    background-color: white;
    border-radius: 1px;
    border: 1px solid whitesmoke;
  
  }
  
  .capture-microchip-take-image:hover {
    cursor: pointer;
    background-color: whitesmoke;
  }
  
  
  
  
  
  
  
  
  
  
  
  @media(max-width: 900px) {
  
    .capture-hero {
      padding: 0;
    }
    
    .capture-hero-intro {
      margin: 0 auto;
      float: none;
      width: 80%;
    }
  
    .capture-hero-logo {
      display: none;
    }
  
    .capture-hero svg {
      display: none;
    }
  
    .capture-section-column {
      margin: 0 auto;
      float: none;
      width: 80%;
    }
    
    .capture-section-column-desktop {
      display: none;
    }
  
  }