.provideritem-listing-item {

  margin: 0;
  margin-bottom: 25px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;
  min-height: 64px;
  max-height: 83px;

}

.provideritem-listing-item-third {
  width: 33.3%;
}

.provideritem-listing-item-fourth {
  width: 25%;
}

.provideritem-listing-item-inner {

  margin: 0;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid whitesmoke;
  box-shadow: 1px 1px 1px 1px #e6e6e6;
  border-bottom: 5px solid #a244a8;

}

.provideritem-listing-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 30%;

}

.provideritem-listing-item-image {

  margin: 0;
  padding: 0;
  display: block;
  width: 65%;
  box-sizing: border-box;
  overflow: hidden;

}

.provideritem-listing-item-image img {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  width: 100%;

}

.provideritem-listing-item-meta {

  margin: 0;
  padding: 5px 0;
  display: block;
  box-sizing: border-box;
  float: right;
  width: 70%;

}

.provideritem-listing-item-meta-name {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

}

.provideritem-listing-item-meta-flag {
  margin-left: 5px;
  font-weight:bold;
  font-size:6px;
  color:red;
}

.provideritem-listing-item-meta-desc {

  margin: 0;
  margin-top: 6px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
  text-transform: lowercase;

}

@media(max-width: 900px) {

  .provideritem-listing-items {
    margin-top: 50px;
    width: 80%;
  }

  .provideritem-listing-item {
    width: 100%;
    float: none;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .provideritem-listing-item-image {

    margin: 0 auto;
    width: 50%;

  }

}