.brand {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.brand-inner {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.brand-content {

  margin: 0;
  padding: 15px 25px;
  box-sizing: border-box;
  display: block;

}

.brand-heading {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 26px;
  line-height: 1.5;

}

.brand-subheading {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 21px;
  line-height: 1.5;

}

.brand-text {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 15px;
  line-height: 1.5;

}

.brand-codeblock {

  margin: 0;
  padding: 25px 30px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: #7F3584;
  font-size: 18px;
  color: white;
  line-height: 1.5;

}

.brand-preview {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  max-height: 128px;

}

.brand-table {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid darkgray;
  border-bottom: 0;
  width: 100%;

}

.brand-table tbody, .brand-table thead {

  width: 100%;

}

.brand-table th {

  padding: 10px 8px;
  color: #222;
  font-weight: bold;
  line-height: 1.4;

}

.brand-table tr {

  width: 100%;
  border-bottom: 1px solid darkgray;

}

.brand-table td {

  padding: 15px 8px;
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  vertical-align: top;

}

.brand-table td.important {

  color: #222;
  font-weight: bold;

}

.brand-color-block {

  margin: 0 auto;
  display: block;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 6px;
  border: 1px solid white;

}

.brand-color-hex {

  font-size: 18px;
  text-align: center;
  width: 100%;

}

.brand-section-columns {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.brand-section-column {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;

}

.brand-section-column-preview {

  padding: 50px 0;

}

.brand-section-column-preview img.brand-height-restricted {

  margin: 0 auto;
  margin-top: -15px;
  padding: 0;
  box-sizing: border-box;
  display: table;
  max-height: 128px;

}

.brand-section-column-preview img.brand-width-restricted {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: table;
  width: 90%;

}

.brand-section-column-preview-usage {

  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  color: #999;
  font-size: 11px;
  width: 90%;
  line-height: 1.5;

}

@media(max-width: 900px) {

  .brand-section-column {

    width: 100%;

  }

  .brand-section-columns .brand-table {

    display: none;

  }

}