.grouprecord-photo-inner {

  margin: 0;
  padding: 35px 10px;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.grouprecord-photo-heading {

  margin: 0;
  margin-bottom: 15px;
  padding: 10px 25px;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 24px;

}

.grouprecord-photo-history {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.grouprecord-photo-preview {

  margin: 0 auto;
  padding: 25px 10px;
  display: block;
  position: relative;
  box-sizing: border-box;
  /* border: 1px dotted #7F3584; */
  border-radius: 14px;
  overflow: hidden;
  width: 90%;
  cursor: pointer;

}

.grouprecord-photo-preview:hover {

  opacity: 0.8;

}

.grouprecord-photo-preview p {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  max-width: 80%;
  text-align: center;
  font-size: 10px;

}

.grouprecord-photo-preview img {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 70%;
  border-radius: 4px;
  overflow: hidden;

}

.grouprecord-photo-history-heading {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 16px;

}

.grouprecord-photo-history-slogan {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 11px;

}

.grouprecord-photo-history-blank-heading {

  margin: 15px 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 18px;

}

.grouprecord-photo-history-blank-slogan {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.6;

}

.grouprecord-photo-history-items {

  margin: 10px 0;
  padding: 10px 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;

}

.grouprecord-photo-history-item {

  margin: 10px 0;
  margin-right: 10px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  float: left;
  /* margin-right: 15px; */

}

.grouprecord-photo-history-item img {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 64px;
  overflow: hidden;
  border-radius: 4px;

}