.groupsettingsmenu {

  margin: 0 auto;
  margin-top: -15px;
  padding: 0;
  background-color: #7F3584;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid #7F3584;
  width: 70%;

}

.groupsettingsmenu-inner {

  margin: 0;
  padding: 0 0;
  
  box-sizing: border-box;
  display: block;

}

.groupsettingsmenu-heading {

  margin: 0 auto;
  margin-top: -5px;
  margin-left: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  font-size: 36px;
  float: left;

}

.groupsettingsmenu-items {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: table;
  border: 0;

}

.groupsettingsmenu-item {

  margin: 0 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  display: block;
  float: left;
  border-radius: 16px;
  overflow: hidden;

}

.groupsettingsmenu-item-last {

  border-right: 0;
  margin-right: 0;

}

.groupsettingsmenu-item .fas {

  margin: 0;
  margin-right: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;

}

.groupsettingsmenu-item p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  font-size: 16px;

}

.groupsettingsmenu-item:hover, .groupsettingsmenu-item.active {

  background-color: white;
  color: #7F3584;
  
}

.groupsettingsmenu-item.active .fas, .groupsettingsmenu-item.active p {

  color: #7F3584;

}

.groupsettingsmenu-item:hover .fas, .groupsettingsmenu-item:hover p {

  color: #7F3584;

}

@media(max-width: 900px) {

  .groupsettingsmenu-items {

    margin: 0 auto;
    display: table;
    float: none;

  }

  .groupsettingsmenu-intro {

    float: none;
    margin: 0 auto;
    margin-bottom: 20px;
    display: table;

  }

  .groupsettingsmenu-item p {
    font-size: 13px;
  }

  .groupsettingsmenu-item .fas {
    font-size: 13px;
  }

}