.animals {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.animals-inner {

  margin: 0;
  padding: 30px 0;
  display: block;

}

.animals-listing-items {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.animals-listing-item {

  margin: 0;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.animals-listing-item-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  height: 100px;

}

.animals-listing-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 30%;
  background-color: #7F3584;

}

.animals-listing-item-image {

  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

}

.animals-listing-item-image img {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  width: 100%;

}

.animals-listing-item-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: right;
  width: 70%;

}

@media(max-width: 900px) {

  .animals-listing-item {
    margin: 0 auto;
    display: block;
    width: 80%;
    float: none;
  }

}