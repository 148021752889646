.groupmenu {

  margin: 0;
  padding: 0;
  background-color: #7F3584;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid #7F3584;

}

.groupmenu-inner {

  margin: 0;
  padding: 15px 10px;
  padding-bottom: 25px;
  
  box-sizing: border-box;
  display: block;

}

.groupmenu-intro {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  float: left;
  text-decoration: none;

}

.groupmenu-intro:hover {

  opacity: 0.9;

}

.groupmenu-logo {

  margin: 0 auto;
  margin-top: -10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  float: left;

}

.groupmenu-logo img {

  height: 46px;
  min-width: 46px;

}

.groupmenu-heading {

  margin: 0 auto;
  margin-top: 3px;
  margin-left: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  font-size: 24px;
  float: left;

}

.groupmenu-items {

  margin: 0;
  margin-top: 2px;
  margin-right: 50px;
  padding: 0;
  float: right;
  box-sizing: border-box;
  display: block;
  border: 1px solid white;
  overflow: hidden;
  border-radius: 6px;

}

.groupmenu-item {

  margin: 0;
  padding: 5px 10px;
  box-sizing: border-box;
  display: block;
  float: left;
  border-right: 1px solid white;

}

.groupmenu-item-last {

  border-right: 0;

}

.groupmenu-item .fas {

  margin: 0;
  margin-right: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;

}

.groupmenu-item p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  font-size: 16px;

}

.groupmenu-item:hover, .groupmenu-item.active {

  background-color: white;
  color: #7F3584;
  
}

.groupmenu-item.active .fas, .groupmenu-item.active p {

  color: #7F3584;

}

.groupmenu-item:hover .fas, .groupmenu-item:hover p {

  color: #7F3584;

}

@media(max-width: 900px) {

  .groupmenu-items {

    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 15px;
    width: 70%;
    display: block;
    float: none;

  }

  .groupmenu-item {

    width: 100%;
    padding: 10px 15px;
    border-right: 0;

  }

  .groupmenu-intro {

    float: none;
    margin: 0 auto;
    margin-bottom: 20px;
    display: table;

  }

  .groupmenu-intro {

    text-decoration: none;

  }

  .groupmenu-heading {

    margin: 0 auto;
    float: none;
    text-decoration: none;

  }

  .groupmenu-logo {

    margin: 0 auto;
    float: none;
    text-align: center;
    text-decoration: none;
    display: none;

  }

}