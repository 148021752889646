.usermenu {

  margin: 0;
  padding: 0;
  background-color: #a244a8;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid #7F3584;

}

.usermenu-inner {

  margin: 0;
  padding: 0;
  
  box-sizing: border-box;
  display: block;

}

.usermenu-items {

  margin: 0 auto;
  padding: 0;
  
  box-sizing: border-box;
  display: table;

}

.usermenu-item {

  margin: 0 5px;
  padding: 15px 15px;
  box-sizing: border-box;
  display: block;
  float: left;

}

.usermenu-item .fas {

  margin: 0;
  margin-right: 15px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;

}

.usermenu-item p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;

}

.usermenu-item:hover, .usermenu-item.active {

  background-color: white;
  color: #7F3584;
  
}

.usermenu-item.active .fas, .usermenu-item.active p {

  color: #7F3584;

}

.usermenu-item:hover .fas, .usermenu-item:hover p {

  color: #7F3584;

}

@media(max-width: 900px) {

  .usermenu {

    display: none;

  }

  .usermenu-items {

    margin: 0;
    width: 100%;
    display: block;

  }

  .usermenu-item {
    margin: 0 auto;
    display: table;
    width: 100%;
  }

}