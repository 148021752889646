.grouprecord {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.grouprecord-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.grouprecord-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
  border-bottom: 1px solid white;

}

.grouprecord-hero-img {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 196px;

}

.grouprecord .details-box {

  margin-top: -250px;

}

.grouprecord .details-box-top {

  margin-top: 30px;

}

.grouprecord-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
  min-height: 110px;

}

.grouprecord-hero-actions {

  margin: 15px 8px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  float: right;

}

.grouprecord-hero-action {

  margin: 0;
  margin-right: 10px;
  padding: 5px 10px;
  display: block;
  position: relative;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  float: left;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 13px;

}

.grouprecord-hero-action > .fas {

  margin-right: 5px;

}

.grouprecord-hero-action:hover {

  background-color: white;
  color: #111;

}

.grouprecord-actions {

  margin: 0 auto;
  margin-top: -20px;
  padding: 0;
  display: table;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #F0F1FA;
  box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);
  z-index: 30000;

}

.grouprecord-action {

  margin: 0;
  padding: 10px 15px;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  float: left;
  color: #666;
  text-transform: uppercase;
  border-right: 1px solid whitesmoke;
  font-size: 13px;

}

.grouprecord-action .fas {

  margin-right: 5px;

}

.grouprecord-action.active, .grouprecord-action:hover {

  background-color: #F0F1FA;
  color: #111;

}

.grouprecord-items-blank {

  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 110px;
  display: block;
  box-sizing: border-box;

}

.grouprecord-items-blank .fas {

  margin: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 164px;
  color: #7F3584;

}

.grouprecord-items-blank p {

  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 21px;
  line-height: 1.4;

}

.grouprecord-hero-action.active {

  color: #7F3584;
  background-color: white;

}

.banner-hero-grouprecord-overview {

  margin-top: -15px;
  
}

@media(max-width: 800px) {
  
  .grouprecord-action {

    padding: 10px 10px;
    font-size: 13px;

  }

  .grouprecord-action .fas {

    display: none;

  }

}