.notfound {

  margin: 0;
  padding: 50px 0;
  padding-top: 15px;
  padding-bottom: 80px;
  display: block;
  background-color: #7F3584;

}

.notfound-heading {

  margin: 0;
  margin-left: 5px;
  margin-top: -30px;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 28px;

}

.notfound-heading-o {

  margin: 0 15px;
  margin-right: 25px;

}

.notfound-slogan {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 21px;

}

.notfound-info {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 17px;

}

.notfound-info a {

  text-decoration: none;
  color: #FAFAFA;
  font-weight: bold;

}

.notfound-info a:hover {

  text-decoration: underline;
  cursor: pointer;
  color: #FAFAFA;

}

.notfound .doggo {

  margin: 0;
  margin-top: 180px;
  padding: 0;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

} 

.notfound .ball {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 4rem;
  z-index: 0;
}
.notfound .ball:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: inset 0 -8px 0 0 rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 1;
}
.notfound .ball:focus {
  outline: none;
}
.notfound .ball:focus:after {
  -webkit-animation: bounce 400ms infinite alternate;
          animation: bounce 400ms infinite alternate;
}
@-webkit-keyframes bounce {
  from {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}
@keyframes bounce {
  from {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}
@-webkit-keyframes bounce-shadow {
  from {
    -webkit-transform: scale(2.5, 2.6) translateY(-50%);
            transform: scale(2.5, 2.6) translateY(-50%);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: scale(0.5) translateY(0);
            transform: scale(0.5) translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}
@keyframes bounce-shadow {
  from {
    -webkit-transform: scale(2.5, 2.6) translateY(-50%);
            transform: scale(2.5, 2.6) translateY(-50%);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: scale(0.5) translateY(0);
            transform: scale(0.5) translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}
.notfound .ball:focus:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  -webkit-animation: bounce-shadow 400ms infinite alternate;
          animation: bounce-shadow 400ms infinite alternate;
  z-index: -10;
}

.notfound .dog {
  width: 100px;
  height: 100px;
  z-index: 1;
}
.notfound .dog:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.03);
  -webkit-transform: translateY(-30%) scale(1.5);
          transform: translateY(-30%) scale(1.5);
}
.notfound .dog * {
  position: absolute;
}

.notfound .dog-body {
  top: -50%;
  -webkit-animation: dog-body 200ms ease-in-out infinite alternate;
          animation: dog-body 200ms ease-in-out infinite alternate;
}
.notfound .dog-body:before {
  content: '';
  position: absolute;
  bottom: 90%;
  right: 50%;
  width: 90%;
  height: 90%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 10%;
  border-top-right-radius: 10%;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-animation: dog-tail-blur 200ms 33.3333333333ms ease-in-out infinite alternate both;
          animation: dog-tail-blur 200ms 33.3333333333ms ease-in-out infinite alternate both;
}
@-webkit-keyframes dog-tail-blur {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes dog-tail-blur {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
}
@-webkit-keyframes dog-body {
  from {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  to {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
}
@keyframes dog-body {
  from {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  to {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
}
.notfound .dog-head {
  -webkit-animation: dog-head 1800ms cubic-bezier(0.11, 0.79, 0, 0.99) infinite;
          animation: dog-head 1800ms cubic-bezier(0.11, 0.79, 0, 0.99) infinite;
}
@-webkit-keyframes dog-head {
  from, to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  33.3% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  66.6% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes dog-head {
  from, to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  33.3% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  66.6% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.notfound .dog-torso {
  top: -20%;
  animation: dog-torso 200ms ease-in-out infinite alternate-reverse;
}
@-webkit-keyframes dog-torso {
  from {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }
}
@keyframes dog-torso {
  from {
    -webkit-transform: translateX(-5%);
            transform: translateX(-5%);
  }
  to {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }
}
.notfound .dog-eyes {
  width: 60%;
  top: 55%;
  left: 20%;
  z-index: 1;
}
.notfound .dog-eyes:before {
  content: '';
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  position: absolute;
  background: orange;
  top: -10px;
  left: -10px;
  z-index: 0;
  border: 4px solid white;
  border-left-width: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.notfound .dog-eye {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  z-index: 1;
  -webkit-animation: dog-eye 1800ms infinite;
          animation: dog-eye 1800ms infinite;
}
@-webkit-keyframes dog-eye {
  from, to {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    opacity: 1;
  }
  50%, 55% {
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    opacity: 0;
  }
}
@keyframes dog-eye {
  from, to {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    opacity: 1;
  }
  50%, 55% {
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    opacity: 0;
  }
}
.notfound .dog-eye:first-child {
  left: 0;
}
.notfound .dog-eye:last-child {
  right: 0;
}

.notfound .dog-muzzle {
  width: 60%;
  left: 20%;
  height: 50%;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background: white;
  bottom: -15%;
}
.notfound .dog-muzzle:before, .dog-muzzle:after {
  content: '';
  display: block;
  position: absolute;
}
.notfound .dog-muzzle:before {
  width: 6px;
  height: 20px;
  bottom: 0;
  left: calc(50% - 3px);
  background: #EAEBEC;
}
.notfound .dog-muzzle:after {
  background: black;
  width: 20px;
  height: 15px;
  bottom: 12px;
  left: calc(50% - 10px);
  border-bottom-left-radius: 60% 60%;
  border-bottom-right-radius: 60% 60%;
  border-top-left-radius: 50% 40%;
  border-top-right-radius: 50% 40%;
}

.notfound .dog-tongue {
  width: 40px;
  height: 100%;
  left: calc(50% - 20px);
  z-index: -1;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-animation: dog-tongue 1800ms -50ms ease-in-out infinite;
          animation: dog-tongue 1800ms -50ms ease-in-out infinite;
}
@-webkit-keyframes dog-tongue {
  from, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  16.6666666667% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  33.3333333333%, 66.6666666667% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50%, 83.3333333333% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
@keyframes dog-tongue {
  from, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  16.6666666667% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  33.3333333333%, 66.6666666667% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50%, 83.3333333333% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
.notfound .dog-tongue:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: #FD3163;
  -webkit-animation: dog-tongue-inner 100ms ease-in-out infinite alternate;
          animation: dog-tongue-inner 100ms ease-in-out infinite alternate;
}
@-webkit-keyframes dog-tongue-inner {
  from {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  to {
    -webkit-transform: translateY(22%);
            transform: translateY(22%);
  }
}
@keyframes dog-tongue-inner {
  from {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  to {
    -webkit-transform: translateY(22%);
            transform: translateY(22%);
  }
}
.notfound .dog-ears {
  width: 40%;
  top: 25%;
  left: 30%;
  -webkit-animation: dog-ears 1800ms 100ms ease infinite;
          animation: dog-ears 1800ms 100ms ease infinite;
}
@-webkit-keyframes dog-ears {
  42.3%, 71.6% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  50.3%, 79.6% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  12% {
    -webkit-transform: rotate(-5%);
            transform: rotate(-5%);
  }
  from, 33.3%, 66%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes dog-ears {
  42.3%, 71.6% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  50.3%, 79.6% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  12% {
    -webkit-transform: rotate(-5%);
            transform: rotate(-5%);
  }
  from, 33.3%, 66%, to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.notfound .dog-ear {
  bottom: -10px;
  height: 50px;
  width: 50px;
  background: #EAEBEC;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.notfound .dog-ear:first-child {
  border-bottom-left-radius: 80%;
  border-top-right-radius: 80%;
  right: 100%;
  box-shadow: inset -15px 15px 0 1px white;
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}
.notfound .dog-ear:last-child {
  border-top-left-radius: 80%;
  border-bottom-right-radius: 80%;
  left: 100%;
  box-shadow: inset 15px 15px 0 0 white;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

.notfound .dog-tail {
  width: 22px;
  height: 24.2px;
  background: white;
  bottom: 40%;
  border-radius: 11px;
  left: calc(50% - 11px);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}
.notfound .dog-tail .dog-tail {
  -webkit-animation: dog-tail-segment 200ms ease-in-out infinite alternate;
          animation: dog-tail-segment 200ms ease-in-out infinite alternate;
}
@-webkit-keyframes dog-tail-segment {
  from {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}
@keyframes dog-tail-segment {
  from {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}
.notfound .dog-body > .dog-tail {
  bottom: 90%;
  -webkit-animation: dog-tail 200ms ease-in-out infinite alternate;
          animation: dog-tail 200ms ease-in-out infinite alternate;
}
@-webkit-keyframes dog-tail {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@keyframes dog-tail {
  from {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
.notfound .dog-body,
.notfound .dog-torso,
.notfound .dog-head {
  border-radius: 50%;
  background: white;
  position: absolute;
  height: 100%;
  width: 100%;
}

.notfound .dog-body, .notfound .dog-torso {
  box-shadow: inset 0 -15px 0 0 #EAEBEC;
}

.notfound *, .notfound *:before, .notfound *:after {
  box-sizing: border-box;
  position: relative;
}

@media(max-width: 900px) {

  .notfound-slogan {
    
    font-size: 11px;

  }

  .notfound-slogan {
    
    margin-right: auto;
    margin-left: auto;
    line-height: 1.5;
    max-width: 60%;

  }

  .notfound-info {
    
    margin-right: auto;
    margin-left: auto;
    line-height: 1.5;
    max-width: 60%;

  }

}