.dai-block {

  margin: 0;
  margin-top: -40px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #3d8361;

}

.dai-preview {

  margin: 0;
  padding: 25px 0;
  padding-bottom: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 30%;

}

.dai-meta {

  margin: 0;
  padding: 25px 0;
  padding-bottom: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 70%;

}

.dai-image {

  margin: 0 auto;
  margin-top: 45px;
  padding: 0;
  display: table;
  box-sizing: border-box;
  height: 128px;

}

.dai-heading {

  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0;
  display: block;
  box-sizing: border-box;

  color: white;
  font-size: 46px;

  font-weight: 100;

}

.dai-heading strong {

  font-weight: bold;
  color: whitesmoke;

}

.dai-description {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  line-height: 1.4;

  color: white;
  font-size: 24px;

}

.dai-cta {

  margin: 0;
  margin-top: 25px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;

  float: left;

  background-color: white;
  color: #3d8361;
  border: 1px solid #3d8361;
  text-decoration: none;

  border-radius: 6px;
  overflow: hidden;

  font-size: 24px;

}

.dai-cta:hover {

  background-color: #3d8361;
  border-color: white;
  color: white;
}

.home {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.home-inner {

  margin: 0;
  padding: 0;
  padding-bottom: 60px;
  display: block;
  box-sizing: border-box;

}

.home-hero {
  margin: 0;
  padding: 50px;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
}

.home-hero-logo {

  margin: 0;
  padding: 0;
  float: right;
  display: block;
  box-sizing: border-box;

}

.home-hero-logo img {

  margin: 0;
  margin-top: -50px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 196px;

}

.home-hero-intro {

  margin: 0 25px;
  padding: 25px 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 70%;

}

.home-hero-intro-text {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  font-size: 21px;
  color: white;

}

.home-header-bg {

  margin: 0;
  padding: 0;
  margin-top: -180px;
  margin-bottom: -58px;
  box-sizing: border-box;
  fill: white;
  float: left;
  z-index: 1000;

}

.home-upload-header {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.home-upload-header img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.home-upload-item {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.home-upload-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 40%;

}

.home-upload-item-preview img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.home-upload-item-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.home-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.home-nav-action-items {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  display: none;

}

.home-nav-section {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;

}

.home-nav-action-left,
.home-nav-action-right {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 20%;
  text-decoration: none;
  color: #666;

}

.home-nav-country {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 60%;
  cursor: pointer;

}

.home-nav-action-flag {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 2px;

}

.home-nav-action-flag img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 32px;

}

.home-nav-action-label {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;

}

.home-cta {

  margin: 0 auto;
  margin-top: 30px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  background-color: white;
  text-decoration: none;
  font-size: 19px;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #7F3584;
  color: white;

}

.home-cta:hover {
  opacity: 0.7;
}

.home-section .wrappingoverlay {
  position: relative;
}

.home-section {

  margin: 0;
  padding: 15px 0;
  display: block;
  box-sizing: border-box;
  position: relative;

}

.home-section-chipndoodle {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #7f3584;

}

.home-section-tools {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #2b2d42;

}

.home-section-petticash {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #F38437;

}

.home-section-about {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #e8eddf;

}

.home-section-developer {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #cfdbd5;

}

.home-section-column {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.home-section-developer-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.home-section-chipndoodle .home-section-column {

  padding: 50px 0;

}

.home-section-chipndoodle-heading {

  margin: 0;
  margin-top: 75px;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  font-size: 26px;

}

.home-section-chipndoodle-slogan {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;

}

.home-section-chipndoodle-link {

  margin: 0;
  margin-left: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 50%;
  border: 1px solid white;
  border-radius: 6px;

}

.home-section-chipndoodle-link-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.home-section-chipndoodle-logo {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 46px;
  float: left;

}

.home-section-chipndoodle-logo-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  color: white;

}

.home-section-chipndoodle-text {

  margin: 0 auto;
  padding: 0;
  padding-left: 60px;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  font-size: 11px;
  line-height: 1.4;

}

.home-section-chipndoodle-logo-desc {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  font-size: 11px;
  line-height: 1.4;

}

.home-section-chipndoodle-search {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;
  width: 75%;

}

.home-section-chipndoodle-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;
  border: 0;
  font-size: 21px;

}

.home-section-chipndoodle-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: white;
  color: #7f3584;

}

.home-section-chipndoodle-search button:hover {

  background-color: #7f3584;
  color: white;
  cursor: pointer;

}

.home-section-chipndoodle-underline {

  margin: 0;
  margin-top: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  font-size: 14px;

}

.home-section-chipndoodle-providers {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.home-section-chipndoodle-providers img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  height: 46px;

}

.home-microchip-search {

  margin: 50px auto;
  margin-top: 25px;
  padding: 0;
  display: table;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;

}

.home-microchip-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 85%;
  border: 0;
  border-right: 1px solid #7f3584;
  font-size: 21px;
  color: #111;

}

.home-microchip-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 15%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: #7f3584;
  color: white;

}

.home-section-chipndoodle-search-submit {

  right: 20% !important;

}

.home-microchip-search button:hover {

  background-color: darkgray;
  color: white;
  cursor: pointer;

}

.home-microchip-cta-button {

  margin: 0 auto;
  margin-top: 50px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  text-decoration: none;
  background-color: #7F3584;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;

}

.home-microchip-cta-button:hover {

  background-color: white;
  color: #7F3584;

}

.home-microchip-cta-button-alt {

  background-color: white;
  color: #7F3584;

}

.home-microchip-cta-button-alt {


  background-color: #7F3584;
  color: white;
  font-size: 16px;

}

.home-microchip-take-image {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 30%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.home-microchip-take-image:hover {
  cursor: pointer;
  background-color: whitesmoke;
}















.home .left {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: right;
  width: 50%;
  background: #FFCA95;
}

.home .right {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: right;
  width: 50%;
  background: #FFCA95;
}

.home .cat {
  height: 182px;
  width: 200px;
  position: absolute;
  right: 10%;
  top: 120px;
  right: 145px;
  transform: translate(0, -50%);
}

.home .cat .ears1 {
  height: 0;
  width: 0;
  position: relative;
  left: 90px;
  border-bottom: 27px solid #475881;
  border-left: 10px solid transparent;
  border-right: 23px solid transparent;
}

.home .cat .ears1::before {
  display: block;
  content: "";
  height: 0;
  width: 0;
  position: relative;
  left: 24px;
  border-bottom: 27px solid #475881;
  border-left: 10px solid transparent;
  border-right: 23px solid transparent;
}

.home .cat .head1 {
  height: 74px;
  width: 135px;
  position: relative;
  left: 65px;
  z-index: 2;
  box-shadow: -8px 0 0 #475881;
  border-radius: 37px;
  background: #7C85AB;
}

.home .cat .head1 .eyes1 {
  height: 12px;
  width: 12px;
  position: relative;
  top: 37px;
  left: 64px;
  border-radius: 100%;
  animation: 9s catRead infinite;
  background: black;
}

.home .cat .head1 .eyes1::before {
  display: block;
  content: "";
  height: 12px;
  width: 12px;
  position: relative;
  left: 18px;
  border-radius: 100%;
  background: black;
}

.home .cat .head1 .nose1 {
  height: 22px;
  width: 22px;
  position: relative;
  top: 40px;
  left: 60px;
  border-radius: 20px;
  background: #FBF1D8;
}

.home .cat .head1 .nose1::before {
  display: block;
  content: "";
  height: 22px;
  width: 22px;
  position: relative;
  left: 22px;
  border-radius: 20px;
  background: #FBF1D8;
}

.home .cat .head1 .nose1::after {
  display: block;
  content: "";
  height: 0;
  width: 0;
  position: relative;
  top: -22px;
  left: 12px;
  border-radius: 10px;
  border-top: 10px solid #FFA5C0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.home .cat .body1 {
  height: 110px;
  width: 200px;
  position: relative;
  top: -30px;
  z-index: 1;
  border-radius: 55px;
  background: #7C85AB;
}

.home .cat .body1 .left-paw1 {
  height: 25px;
  width: 37px;
  position: relative;
  top: 70px;
  left: 95px;
  border-radius: 12px;
  animation: 9s catLeftType infinite;
  background: #FBF1D8;
}

.home .cat .body1 .right-paw1 {
  height: 25px;
  width: 37px;
  position: relative;
  top: 45px;
  left: 142px;
  border-radius: 12px;
  animation: 9s catRightType infinite;
  background: #FBF1D8;
}

.home .cat .tail1 {
  height: 24px;
  width: 80px;
  position: relative;
  top: -54px;
  left: -31px;
  z-index: 0;
  border-radius: 17px 0 0 17px;
  background: #475881;
}

.home .cat .PRlaptop {
  position: relative;
  top: -151px;
  left: 170px;
  z-index: 2;
}

.home .cat .PRlaptop .PRscreen {
  height: 85px;
  width: 130px;
  border-radius: 8px;
  transform: skew(-18deg);
  background: #20314E;
}

.home .cat .PRlaptop .PRscreen::before {
  display: block;
  content: "";
  height: 17px;
  width: 10px;
  position: relative;
  top: 38px;
  left: 56px;
  border-radius: 6px;
  background: #475881;
}

.home .cat .PRlaptop .PRscreen::after {
  display: block;
  content: "";
  height: 17px;
  width: 10px;
  position: relative;
  top: 21px;
  left: 70px;
  border-radius: 6px;
  background: #475881;
}

.home .cat .PRlaptop .PRkeyboard {
  height: 12px;
  width: 132px;
  position: relative;
  left: -14px;
  border-radius: 0 6px 6px 0;
  background: #475881;
}

.home .cat .PRlaptop .PRkeyboard::before {
  display: block;
  content: "";
  height: 12px;
  width: 72px;
  position: relative;
  left: -68px;
  border-radius: 6px;
  background: #20314E;
}

.home .dog {
  height: 182px;
  position: absolute;
  top: 50%;
  left: 145px;
  transform: translate(0, -50%);
}

.home .dog .ears2 {
  height: 0;
  width: 0;
  position: relative;
  left: 30px;
  border-bottom: 27px solid #F07E42;
  border-left: 23px solid transparent;
  border-right: 10px solid transparent;
}

.home .dog .ears2::before {
  display: block;
  content: "";
  height: 0;
  width: 0;
  position: relative;
  left: 33px;
  border-bottom: 27px solid #F07E42;
  border-left: 10px solid transparent;
  border-right: 23px solid transparent;
}

.home .dog .head2 {
  height: 74px;
  width: 140px;
  position: relative;
  z-index: 2;
  box-shadow: 8px 0 0 #F07E42;
  border-radius: 35px;
  background: #FFA852;
}

.home .dog .head2 .eyes2 {
  height: 12px;
  width: 12px;
  position: relative;
  top: 37px;
  left: 50px;
  border-radius: 100%;
  animation: 9s dogRead infinite;
  background: black;
}

.home .dog .head2 .eyes2::before {
  display: block;
  content: "";
  height: 12px;
  width: 12px;
  position: relative;
  left: 18px;
  border-radius: 100%;
  background: black;
}

.home .dog .head2 .nose2 {
  height: 30px;
  width: 45px;
  position: relative;
  top: 40px;
  left: 37px;
  border-radius: 20px;
  background: #FBF1D8;
}

.home .dog .head2 .nose2::before {
  display: block;
  content: "";
  height: 0;
  width: 0;
  position: relative;
  top: 3px;
  left: 9px;
  border-radius: 10px;
  border-top: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.home .dog .body2 {
  height: 110px;
  width: 200px;
  position: relative;
  top: -30px;
  z-index: 1;
  border-radius: 55px;
  background: #FFA852;
}

.home .dog .body2 .left-paw2 {
  height: 25px;
  width: 37px;
  position: relative;
  top: 70px;
  left: 15px;
  border-radius: 12px;
  animation: 9s dogLeftType infinite;
  background: #FBF1D8;
}

.home .dog .body2 .right-paw2 {
  height: 25px;
  width: 37px;
  position: relative;
  top: 45px;
  left: 60px;
  border-radius: 12px;
  animation: 9s dogRightType infinite;
  background: #FBF1D8;
}

.home .dog .tail2 {
  height: 34px;
  width: 70px;
  position: relative;
  top: -64px;
  left: 150px;
  z-index: 0;
  border-radius: 0 17px 17px 0;
  background: #F07E42;
}

.home .dog .ORlaptop {
  position: relative;
  top: -161px;
  left: -103px;
  z-index: 2;
}

.home .dog .ORlaptop .ORscreen {
  height: 85px;
  width: 130px;
  border-radius: 8px;
  transform: skew(18deg);
  background: #FFCA95;
}

.home .dog .ORlaptop .ORscreen::before {
  display: block;
  content: "";
  height: 17px;
  width: 10px;
  position: relative;
  top: 38px;
  left: 50px;
  border-radius: 6px;
  background: #F07E42;
}

.home .dog .ORlaptop .ORscreen::after {
  display: block;
  content: "";
  height: 17px;
  width: 10px;
  position: relative;
  top: 21px;
  left: 64px;
  border-radius: 6px;
  background: #F07E42;
}

.home .dog .ORlaptop .ORkeyboard {
  height: 12px;
  width: 132px;
  position: relative;
  left: 14px;
  border-radius: 6px 0 0 6px;
  background: #F07E42;
}

.home .dog .ORlaptop .ORkeyboard::before {
  display: block;
  content: "";
  height: 12px;
  width: 72px;
  position: relative;
  left: 128px;
  border-radius: 6px;
  background: #FFCA95;
}

@keyframes catLeftType {
  2% {
    transform: translateY(-8px);
  }

  6% {
    transform: none;
  }

  8% {
    transform: translateY(-8px);
  }

  10% {
    transform: none;
  }

  14% {
    transform: translateY(-8px);
  }

  16% {
    transform: none;
  }

  18% {
    transform: translateY(-8px);
  }

  20% {
    transform: none;
  }

  22% {
    transform: translateY(-8px);
  }

  26% {
    transform: none;
  }
}

@keyframes catRightType {
  6% {
    transform: translateY(-8px);
  }

  8% {
    transform: none;
  }

  10% {
    transform: translateY(-8px);
  }

  12% {
    transform: none;
  }

  16% {
    transform: translateY(-8px);
  }

  18% {
    transform: none;
  }

  20% {
    transform: translateY(-8px);
  }

  22% {
    transform: none;
  }

  24% {
    transform: translateY(-8px);
  }

  28% {
    transform: none;
  }
}

@keyframes catRead {
  55% {
    transform: none;
  }

  62% {
    transform: translateX(-2px);
  }

  70% {
    transition-timing-function: ease-out;
    transform: translateX(3px);
  }

  82% {
    transform: translateX(-2px);
  }

  90% {
    transition-timing-function: ease-out;
    transform: translateX(3px);
  }

  100% {
    transform: none;
  }
}

@keyframes dogLeftType {
  50% {
    transform: none;
  }

  52% {
    transform: translateY(-8px);
  }

  56% {
    transform: none;
  }

  58% {
    transform: translateY(-8px);
  }

  60% {
    transform: none;
  }

  64% {
    transform: translateY(-8px);
  }

  66% {
    transform: none;
  }

  68% {
    transform: translateY(-8px);
  }

  70% {
    transform: none;
  }

  72% {
    transform: translateY(-8px);
  }

  76% {
    transform: none;
  }
}

@keyframes dogRightType {
  54% {
    transform: none;
  }

  56% {
    transform: translateY(-8px);
  }

  58% {
    transform: none;
  }

  60% {
    transform: translateY(-8px);
  }

  62% {
    transform: none;
  }

  66% {
    transform: translateY(-8px);
  }

  68% {
    transform: none;
  }

  70% {
    transform: translateY(-8px);
  }

  72% {
    transform: none;
  }

  74% {
    transform: translateY(-8px);
  }

  78% {
    transform: none;
  }
}

@keyframes dogRead {
  5% {
    transform: none;
  }

  17% {
    transition-timing-function: ease-out;
    transform: translateX(-5px);
  }

  25% {
    transform: none;
  }

  37% {
    transition-timing-function: ease-out;
    transform: translateX(-5px);
  }

  45% {
    transform: none;
  }
}

.home .home-providers-heading {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  padding: 10px 0;
  text-align: center;
  color: #7F3584;
  font-weight: bold;
}

.home .home-providers-pills-bg {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: #7F3584;
  border-radius: 32px;
}

.home .home-providers-pills {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  display: table;
  background-color: #7F3584;
  border-radius: 32px;
  overflow: hidden;
}

.home .home-providers-pills .home-providers-pill {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  padding: 5px;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner:hover {
  cursor: pointer;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner img {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  margin-right: 10px;
  height: 18px;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner img.light {
  display: block !important;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner img.color {
  display: none !important;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner span {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  font-size: 16px;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner span.home-providers-pill-inner-small {
  display: block;
}

.home .home-providers-pills .home-providers-pill .home-providers-pill-inner span.home-providers-pill-inner-small {
  display: none;
}

.home .home-providers-pills .home-providers-pill.active .home-providers-pill-inner,
.home .home-providers-pills .home-providers-pill:hover .home-providers-pill-inner {
  background-color: white;
}

.home .home-providers-pills .home-providers-pill.active .home-providers-pill-inner img.light,
.home .home-providers-pills .home-providers-pill:hover .home-providers-pill-inner img.light {
  display: none !important;
}

.home .home-providers-pills .home-providers-pill.active .home-providers-pill-inner img.color,
.home .home-providers-pills .home-providers-pill:hover .home-providers-pill-inner img.color {
  display: block !important;
}

.home .home-providers-pills .home-providers-pill.active .home-providers-pill-inner span,
.home .home-providers-pills .home-providers-pill:hover .home-providers-pill-inner span {
  color: #7F3584;
}

.home .home-providers-pills .home-providers-pill-left.active .home-providers-pill-inner,
.home .home-providers-pills .home-providers-pill-left:hover .home-providers-pill-inner {
  background-color: white;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}

.home .home-providers-pills .home-providers-pill-left.active .home-providers-pill-inner span,
.home .home-providers-pills .home-providers-pill-left:hover .home-providers-pill-inner span {
  color: #7F3584;
}

.home .home-providers-pills .home-providers-pill-center.active .home-providers-pill-inner,
.home .home-providers-pills .home-providers-pill-center:hover .home-providers-pill-inner {
  background-color: white;
  border-radius: 0px;
}

.home .home-providers-pills .home-providers-pill-center.active .home-providers-pill-inner span,
.home .home-providers-pills .home-providers-pill-center:hover .home-providers-pill-inner span {
  color: #7F3584;
}

.home .home-providers-pills .home-providers-pill-right.active .home-providers-pill-inner,
.home .home-providers-pills .home-providers-pill-right:hover .home-providers-pill-inner {
  background-color: white;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}

.home .home-providers-pills .home-providers-pill-right.active .home-providers-pill-inner span,
.home .home-providers-pills .home-providers-pill-right:hover .home-providers-pill-inner span {
  color: #7F3584;
}

.home-listing-items {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  width: 65%;

}

.home .home-providers-legal {

  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  display: table;
  padding: 50px 0;
  font-size: 13px;
  color: #666;
  text-align: center;
  max-width: 45%;
  color: #721c24;
  font-weight: bold;
  text-transform: lowercase;
  line-height: 1.8;

}

@media(max-width: 900px) {

  .dai-preview {
    width: 100%;
    float: unset;
    /* display: none; */
  }

  .dai-meta {
    width: 100%;
    float: unset;
  }

  .dai-heading {
    margin: 0 auto;
    margin-top: 10px;
    display: table;
    text-align: center;
    max-width: 80%;
    font-size: 28px;
  }

  .dai-description {
    margin: 0 auto;
    margin-top: 25px;
    display: table;
    text-align: center;
    max-width: 90%;
    font-size: 18px;
    line-height: 1.4;
  }

  .dai-cta {
    margin: 0 auto;
    margin-top: 30px;
    display: table;
    float: unset;
    font-size: 32px;
  }

  .home-hero {
    padding: 0;
  }

  .home-hero-intro {
    margin: 0 auto;
    float: none;
    width: 80%;
  }

  .home-hero-logo {
    display: none;
  }

  .home-hero svg {
    display: none;
  }

  .home-section-column {
    margin: 0 auto;
    float: none;
    width: 80%;
  }

  .home-section-column-desktop {
    display: none;
  }

  .home .home-providers-heading {
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 15px 0;
    padding-top: 10px;
    text-align: center;
    max-width: 70%;
    line-height: 1.5;
    font-size: 16px;
  }

  .home .home-providers-legal {

    max-width: 75%;

  }

  .home .home-providers-pills .home-providers-pill .home-providers-pill-inner span.home-providers-pill-inner-small {
    display: block;
  }

  .home .home-providers-pills .home-providers-pill .home-providers-pill-inner span.home-providers-pill-inner-big {
    display: none;
  }

  .home-cta {

    margin-top: 50px;
    font-size: 21px;

  }

}