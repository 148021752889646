.header {

  margin: 0;
  padding: 25px 0;
  display: block;
  width: 100%;
  border: 0;

}

.header-logo {

  margin:  0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  float: left;

}

.header-logo-img {
  margin:  0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  height: 36px;
}

.header-logo-img.header-logo-img-inactive {
  display: block;
}

.header-logo-img.header-logo-img-active {
  display: hidden;
}

.header-logo-text {
  margin:  0;
  margin-top: 2px;
  margin-left: 7px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  font-size: 28px;
}

.header-logo:hover {
  opacity: 0.85;
}

.header-logo:hover .header-logo-img.header-logo-img-inactive {
  display: hidden;
}

.header-logo:hover .header-logo-img.header-logo-img-active {
  display: block;
}


.header-nav {

  margin:  0;
  margin-right: 55px;
  padding: 0;
  padding-top: 2px;
  box-sizing: border-box;
  display: block;
  float: right;

}

.header-nav-item {

  margin:  0;
  margin-left: 5px;
  padding: 8px 10px;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  float: left;
  overflow: hidden;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;

}

.header-nav-item:hover, .header-nav-item.active {

  background-color: white;
  color: #7F3584;

}

.home-trends-item {

  margin:  0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;

}

.home-trends-item-inner {

  margin:  5px 15px;
  padding: 5px 10px;
  box-sizing: border-box;
  display: block;
  background-color: white;

}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 28px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  overflow: hidden;
  border-radius: 12px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #F0F1F4;
  padding: 0;
  padding-top: 50px;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #F0F1F4;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0;
}

/* Individual item */
.bm-item {
  margin: 0;
  padding: 15px;
  display: block;
  width: 100%;
  color: white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.9);
}

.header-nav-mobile-items {

  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;

}

.header-nav-mobile-item {

  margin: 0;
  padding: 15px;
  border-bottom: 1px solid white;
  width: 100%;
  display: block;
  box-sizing: border-box;

}

.header-nav-mobile-item .fas {

  margin: 0;
  margin-right: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  color: #7F3584;

}

.header-nav-mobile-item p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  color: #7F3584;

}

.header-nav-mobile-item:hover, .header-nav-mobile-item.active {

  background-color: #7F3584;
  color: white;
  
}

.header-nav-mobile-item.active .fas, .header-nav-mobile-item.active p {

  color: white;

}

.header-nav-mobile-item:hover .fas, .header-nav-mobile-item:hover p {

  color: white;

}

@media(max-width: 900px) {

  .header {

    padding: 25px 30px;
    padding-top: 25px;

  }

  .header-nav {
    display: none;
  }

  .bm-burger-button {
    display: block;
  }

}