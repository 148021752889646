.groupsearches {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupsearches-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 130px;
  display: block;

}

.groupsearches-items-blank {

  margin: 0;
  padding: 0;
  padding-bottom: 40px;
  display: block;
  box-sizing: border-box;

}

.groupsearches-items-blank .fas {

  margin: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 164px;
  color: #7F3584;

}

.groupsearches-items-blank p {

  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 21px;
  line-height: 1.4;

}

.groupsearches-filter-form {

  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #7F3584;
  width: 70%;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid lightgray;

}

.groupsearches-filter-form > input {

  margin: 0 auto;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  font-size: 21px;

}

.groupsearches-heading {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 26px;
  text-align: center;
  color: #7F3584;
  text-transform: uppercase;
  max-width: 80%;
  line-height: 1.5;

}

.groupsearches-heading-slogan {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 13px;
  text-align: center;
  color: #666;
  text-transform: uppercase;
  max-width: 80%;
  line-height: 1.5;

}

.groupsearches-listing-items {

  margin: 0 auto;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.groupsearches-listing-item {

  margin: 5px 0;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  width: 50%;
  float: left;

}

.groupsearches-listing-item-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0.2em 0.5em 1.5em -0.5em rgba(0, 0, 0, 0.5);
  position: relative;
  border-bottom: 8px solid #7F3584;
  overflow: hidden;
  border-radius: 4px;

}

.groupsearches-listing-item-inner:hover {
  opacity: 0.8;
}

.groupsearches-listing-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 40%;
  float: left;
  overflow: hidden;
  background-color: darkgray;

}

.groupsearches-listing-item-image {

  margin: 0 auto;
  padding: 0;
  display: block;
  /* width: 40%; */
  width: 100%;
  box-sizing: border-box;

}

.groupsearches-listing-item-meta {

  margin: 0;
  padding: 13px;
  display: block;
  box-sizing: border-box;
  width: 60%;
  float: left;

}

.groupsearches-listing-item-meta > p {

  margin: 0;
  margin-top: 8px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  float: right;

}

.groupsearches-listing-item-meta-labels {

  margin: 0;
  padding: 20px 30px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.groupsearches-listing-item-meta-labels > h3 {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  text-transform: uppercase;

}

.groupsearches-listing-item-meta-labels > p {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;

}

.groupsearches-listing-item-meta-labels > p > img {

  display: inline;
  height: 12px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 2px;

}

.groupsearches-listing-item-meta-column {

  margin: 0;
  padding: 10px 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20%;

}

.groupsearches-listing-item-meta-column-icon {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: #7F3584;
  font-size: 21px;

}

.groupsearches-listing-item-meta-column-counter {

  margin: 5px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: #666;
  font-size: 21px;

}

.groupsearches-listing-item-meta-column-label {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: gray;
  font-size: 9px;
  max-width: 50%;
  line-height: 1.3;

}

.groupsearches-controlbar-cta {
  
  margin: 0;
  padding: 15px 25px;
  display: block;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  float: left;
  background-color: white;
  text-decoration: none;
  color: #7F3584;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #7F3584;

  background-color: #7F3584;
  color: white;
  float: right;

}

.groupsearches-controlbar-cta:hover {

  background-color: white;
  border: 1px solid #7F3584;
  color: #7F3584;

}

.groupsearches-listing-item-meta-banner {

  margin: 0 auto;
  margin-bottom: 10px;
  padding: 8px 12px;
  box-sizing: border-box;
  display: block;
  float: right;
  background-color: lightgray;
  background-color: #7F3584;
  overflow: hidden;
  border-radius: 12px;

}

.groupsearches-listing-item-meta-banner p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-transform: uppercase;
  color: white;
  float: left;
  font-size: 17px;

}

.groupsearches-listing-item-meta-banner .fas {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  float: left;
  margin-right: 5px;
  font-size: 17px;

}

@media(max-width: 900px) {

  .groupsearches-listing-items {

    margin: 0 auto;
    width: 80%;

  }

  .groupsearches-listing-item {

    width: 100%;

  }

  .groupsearches-listing-item-inner {

    padding-right: 25px;

  }

  .groupsearches-listing-item-meta-labels > p > img {

    display: none;

  }

  .groupsearches-listing-item-meta-labels > h3 {

    margin-top: 7px;
    font-size: 16px;

  }

  .groupsearches-listing-item-meta-labels > p {

    font-size: 9px;

  }

  .groupsearches-listing-item-preview {

    width: 108%;
    background-color: gray;
    border-bottom: 1px solid whitesmoke;
    max-height: 120px;

  }

  .groupsearches-listing-item-meta {

    width: 100%;

  }

  .groupsearches-listing-item-image {

    margin-top: -60px;
    width: 100%;

  }

}