.searches {

    margin: 0;
    padding: 0;
    display: block;
    background-color: #FAFAFA;
  
}
  
.searches-inner {
  
    margin: 0;
    padding: 30px 0;
    display: block;
  
}
  
.searches-listing-items {
  
    margin: 0 auto;
    padding: 0;
    display: block;
    box-sizing: border-box;
  
}
  
.searches-listing-item {
  
    margin: 0;
    padding: 10px;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 50%;
  
}
  
.searches-listing-item-inner {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    height: 100px;
  
}
  
.searches-listing-item-preview {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 30%;
    background-color: #7F3584;
  
}
  
.searches-listing-item-image {
  
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  
}
  
.searches-listing-item-image img {
  
    margin: 0 auto;
    padding: 0;
    display: table;
    box-sizing: border-box;
    width: 100%;
  
}
  
.searches-listing-item-meta {
  
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: right;
    width: 70%;
  
}

.searches-items {

    margin: 0 auto;
    padding: 0;
    display: block;
    box-sizing: border-box;
    width: 85%;

}

.searches-item {

    margin: 0;
    margin-bottom: 10px;
    padding: 15px 25px;
    display: block;
    box-sizing: border-box;
    border: 1px solid whitesmoke;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;

}

.searches-item:hover {
    
    opacity: 0.7;

}

.searches-item-meta {

    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 60%;

}

.searches-item-counter {

    margin: 0;
    padding: 0;
    padding-top: 3px;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 20%;
    border-left: 1px solid whitesmoke;

}

.searches-item-counter h2 {

    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    text-align: center;
    font-size: 13px;

}

.searches-item-counter p {

    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    text-align: center;
    color: #888;
    font-size: 9px;

}

.searches-item-meta-number {

    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: #111;
    font-size: 18px;

}

.searches-item-meta-extra {

    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    color: #888;
    font-size: 11px;

}

@media(max-width: 900px) {
    
    .searches-items {

        width: 90%;

    }

    .searches-item-counter p {

        font-size: 9px;

    }

    .searches-item-meta-extra {

        font-size: 9px;

    }

}