.groupmembers {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.groupmembers-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 130px;
  box-sizing: border-box;
  display: block;

}

.groupmembers-wrapper {

  margin: 0;
  padding: 25px;
  display: block;
  box-sizing: border-box;

}

.groupmembers-heading {

  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  font-size: 21px;
  color: #7F3584;

}

.groupmembers-actions {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: right;

}

.groupmembers-action {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  color: white;
  background-color: #7F3584;
  overflow: hidden;
  font-size: 15px;
  border-radius: 6px;
  text-decoration: none;
  text-transform: uppercase;

}

.groupmembers-items {

  margin: 15px 0;
  margin-top: 35px;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.groupmembers-item {

  margin: 0;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  border-bottom: 1px solid #999;

}

.groupmembers-item-last {

  border-bottom: 0;

}

.groupmembers-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 15%;
  
}

.groupmembers-item-preview > img {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  max-height: 38px;
  overflow: hidden;
  border-radius: 6px;

}

.groupmembers-item-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 55%;
  
}

.groupmembers-item-meta > h3 {

  margin: 0;
  margin-bottom: 7px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 17px;
  
}

.groupmembers-item-meta > p {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: gray;
  font-size: 12px;
  line-height: 1.4;
  
}

.groupmembers-item-role {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20%;
  
}

.groupmembers-item-role > select {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  cursor: pointer;
  
}

.groupmembers-item-actions {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 10%;
  
}

.groupmembers-item-actions > a {

  margin: 5px auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  color: #7F3584;
  font-size: 20px;
  
}

.groupmembers-item-actions > a:hover {

  color: #666;

}

.groupmembers-add-box {
  
  width: 60%;

}