.tag {

  margin: 0;
  padding: 0;
  display: block;

}

.tag-inner {

  margin: 0;
  padding: 0 0;
  padding-top: 40px;
  display: block;

}

.tag-hero {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.tag-hero-pills {

  margin: 20px auto;
  margin-bottom: 40px;
  padding: 0;
  display: table;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid white;
  overflow: hidden;
  border-radius: 6px;
  background-color: #7F3584;

}

.tag-hero-pill {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;

}

.tag-hero-pill.active {

  color: #7F3584;
  background-color: white;

}

.tag-hero-heading {

  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 36px;
  text-align: center;
  color: white;

}

.tag-hero-slogan {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 16px;
  line-height: 1.6;
  color: white;

}

.tag-hero-preview {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  width: 50%;

}

.tag-hero-preview img {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  width: 60%;

}

.tag-hero-slogan {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;

}

.tag-icon {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  text-align: center;
  font-size: 46px;
  color: white;

}

.tag-heading {

  margin: 0;
  padding: 0;
  display: block;
  text-align: center;
  color: white;
  font-size: 49px;

}

.tag-slogan {

  margin: 15px 0;
  padding: 0;
  display: block;
  text-align: center;
  color: #111;
  font-size: 18px;
  text-transform: uppercase;

}

.tag-form {

  margin: 0 auto;
  padding: 25px 20px;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

}

.tag-form-control {

  margin: 0;
  padding: 8px 15px;
  display: block;
  clear: both;

}

.tag-form-control label {

  margin: 0;
  padding: 5px 0;
  padding-bottom: 15px;
  display: block;
  box-sizing: border-box;
  font-size: 17px;
  text-transform: uppercase;
  color: #666;
  width: 100%;

}

.tag-form-control input {

  margin: 0;
  padding: 15px 15px;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  overflow: hidden;
  border: 1px solid whitesmoke;
  border-radius: 4px;

}

.tag-form-control input:hover {

  border-color: gray;

}

.tag-form-control a {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  float: left;
  font-size: 14px;
  text-decoration: none;

}

.tag-form-control a:hover {

  text-decoration: underline;

}

.tag-form-control button {

  margin: 0;
  padding: 5px 10px;
  display: block;
  float: right;
  background-color: #7F3584;
  border: 1px solid #7F3584;
  font-size: 18px;
  color: white;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;

}

.tag-form-control button:hover {

  background-color: white;
  color: #53AE82;

}

@media(max-width: 800px) {

  .tag-hero-preview img {

    width: 80%;

  }

  .tag-hero-pill {

    font-size: 12px;

  }

}