.groupsearch {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupsearch-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.groupsearch-information-banner {

  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  display: block;
  background-color: lightgray;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

}

.groupsearch-information-banner p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;

}

.groupsearch-section-heading {

  margin: 40px auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  max-width: 80%;

}

.groupsearch-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;

}

.groupsearch .details-box {

  margin-top: 30px;

}

.groupsearch .details-box-first {

  margin-top: -250px;

}

.groupsearch .groupsearch-columns {

  margin: 0;
  padding: 75px 25px;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.groupsearch .groupsearch-column {

  margin: 0;
  padding: 0;
  padding-top: 35px;
  display: block;
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 33.3%;
  height: 180px;
  overflow: hidden;

}

.groupsearch .groupsearch-column-padding-top {
  
  padding-top: 50px;

}

.groupsearch .groupsearch-column-bottom {
  
  border-bottom: 1px solid lightgray;

}

.groupsearch .groupsearch-column-left {
  
  border-left: 1px solid lightgray;

}

.groupsearch .groupsearch-column-right {
  
  border-right: 1px solid lightgray;

}

.groupsearch .groupsearch-column .groupsearch-column-icon {
 
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupsearch .groupsearch-column .groupsearch-column-icon img {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  height: 32px;
  overflow: hidden;
  border-radius: 6px;

}

.groupsearch .groupsearch-column .groupsearch-column-icon .fas, .groupsearch .groupsearch-column .groupsearch-column-icon .fab {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 28px;
  color: #7F3584;

}

.groupsearch .groupsearch-column .groupsearch-column-label {
 
  margin: 0;
  padding: 8px;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupsearch .groupsearch-column .groupsearch-column-label h4 {
 
  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #666;
  max-width: 60%;
    
}

.groupsearch .groupsearch-column .groupsearch-column-label p {
 
  margin: 0 auto;
  margin-top: 8px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #666;
  max-width: 60%;
  line-height: 1.4;
    
}

.groupsearch .groupsearch-column .groupsearch-column-desc {
 
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupsearch .groupsearch-column .groupsearch-column-desc p {
 
  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 11px;
  color: darkgray;
  max-width: 60%;
  line-height: 1.4;
    
}

@media(max-width: 900px) {

  .groupsearch .groupsearch-columns {
  }

  .groupsearch .groupsearch-column {

    width: 50%;

  }

  .groupsearch .groupsearch-column-padding-top {
  
    padding-top: 50px;
  
  }
  
  .groupsearch .groupsearch-column-bottom {
    
    border: 0;
  
  }
  
  .groupsearch .groupsearch-column-left {
    
    border: 0;
  
  }
  
  .groupsearch .groupsearch-column-right {
    
    border: 0;
  
  }

}