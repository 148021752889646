.profile {

  margin: 0;
  padding: 0;
  display: block;

}

.profile-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 60px;
  padding-bottom: 140px;
  display: block;

}

.profile .hero-box {

  padding-top: 70px;

}

.profile .details-box {

  margin-top: -150px;

}