.groupintegration {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupintegration-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 130px;
  display: block;

}