.searchheader {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-hero {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
}

.searchheader-home-intro {

  margin: 0;
  padding: 80px 0;
  padding-bottom: 10px;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.searchheader-home-logo {

  margin: 0 auto;
  padding: 0;
  display: table;
  height: 96px;

}

.searchheader-home-headline {

  margin: 0 auto;
  margin-top: 18px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  
  text-align: center;
  max-width: 80%;
  font-size: 32px;
  color: #363457;
  font-weight: bold;

}

.searchheader-home-slogan {

  margin: 30px auto;
  margin-bottom: 40px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 25px;
  width: 80%;
  word-break: normal;
  text-align: center;
  font-size: 16px;
  color: #444;
  line-height: 1.6;

}

.searchheader-hero-searches {

  margin: 0;
  padding: 30px 0;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.searchheader-hero-home {
  padding: 50px;
}

.searchheader-hero-logo {

  margin: 0;
  padding: 0;
  float: right;
  display: block;
  box-sizing: border-box;

}

.searchheader-hero-logo img {

  margin: 0;
  margin-top: -10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 196px;

}

.searchheader-hero-intro {

  margin: 0 25px;
  padding: 25px 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 70%;

}

.searchheader-hero-intro-text {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  font-size: 21px;
  color: white;

}

.searchheader-header-bg {

  margin: 0;
  padding: 0;
  margin-top: -180px;
  margin-bottom: -58px;
  box-sizing: border-box;
  fill: white;
  float: left;
  z-index: 1000;

}

.searchheader-upload-header {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-upload-header img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.searchheader-upload-item {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-upload-item-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 40%;

}

.searchheader-upload-item-preview img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.searchheader-upload-item-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.searchheader-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-nav-action-items {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  display: none;

}

.searchheader-nav-section {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;

}

.searchheader-nav-action-left, .searchheader-nav-action-right {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 20%;
  text-decoration: none;
  color: #666;

}

.searchheader-nav-country {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 60%;
  cursor: pointer;

}

.searchheader-nav-action-flag {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 2px;

}

.searchheader-nav-action-flag img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 32px;

}

.searchheader-nav-action-label {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;

}

.searchheader-hero-cta {

  margin: 0;
  margin-top: 20px;
  padding: 15px 25px;
  display: block;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  float: left;
  background-color: white;
  text-decoration: none;
  color: #7F3584;
  font-size: 24px;
  cursor: pointer;

}

.searchheader-hero-cta:hover {

  background-color: #7F3584;
  color: white;

}

.searchheader-section .wrappingoverlay {
  position: relative;
}

.searchheader-section {

  margin: 0;
  padding: 15px 0;
  display: block;
  box-sizing: border-box;
  position: relative;

}

.searchheader-section-chipndoodle {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #7f3584;

}

.searchheader-section-tools {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #2b2d42;

}

.searchheader-section-petticash {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #F38437;

}

.searchheader-section-about {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #e8eddf;

}

.searchheader-section-developer {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #cfdbd5;

}

.searchheader-section-column {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.searchheader-section-developer-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.searchheader-section-chipndoodle .searchheader-section-column {

  padding: 50px 0;

}

.searchheader-section-chipndoodle-heading {

  margin: 0;
  margin-top: 75px;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  font-size: 26px;

}

.searchheader-section-chipndoodle-slogan {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;

}

.searchheader-section-chipndoodle-link {

  margin: 0;
  margin-left: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 50%;
  border: 1px solid white;
  border-radius: 6px;

}

.searchheader-section-chipndoodle-link-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.searchheader-section-chipndoodle-logo {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 46px;
  float: left;

}

.searchheader-section-chipndoodle-logo-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  color: white;

}

.searchheader-section-chipndoodle-text {

  margin: 0 auto;
  padding: 0;
  padding-left: 60px;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  font-size: 11px;
  line-height: 1.4;

}

.searchheader-section-chipndoodle-logo-desc {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  font-size: 11px;
  line-height: 1.4;

}

.searchheader-section-chipndoodle-search {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;
  width: 75%;

}

.searchheader-section-chipndoodle-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 65%;
  border: 0;
  font-size: 21px;
    
}

.searchheader-section-chipndoodle-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 35%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: white;
  color: #7f3584;
    
}

.searchheader-section-chipndoodle-search button:hover {

  background-color: #7f3584;
  color: white;
  cursor: pointer;

}

.searchheader-section-chipndoodle-underline {

  margin: 0;
  margin-top: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  font-size: 14px;

}

.searchheader-section-chipndoodle-providers {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchheader-section-chipndoodle-providers img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  height: 46px;

}

.searchheader-microchip-search-banner-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  text-align: center;

}

.searchheader-microchip-search {

  margin: 50px auto;
  margin-top: 25px;
  padding: 0;
  display: table;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;

}

.searchheader-microchip-search-center {

  width: 50%;

}

.searchheader-microchip-search-home {

  width: 65%;
  margin-bottom: 25px;
  border: 1px solid lightgray;

}

.searchheader-microchip-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 70%;
  border: 0;
  border-right: 1px solid #7f3584;
  font-size: 21px;
  color: #111;
    
}

.searchheader-microchip-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 30%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: #7f3584;
  color: white;
    
}

.searchheader-microchip-search-home > button.disabled {
  opacity: 0.7;
}

.searchheader-microchip-search-home > button > span {

  margin: 0 auto;
  padding: 0;
  display: block;
  text-align: center;

}

.searchheader-microchip-search-home > button > img, .searchheader-microchip-search-center > button > img {

  margin: 0 auto;
  padding: 0;
  display: block;
  height: 23px;

}

.searchheader-section-chipndoodle-search-submit {

  right: 20% !important;
    
}

.searchheader-microchip-search button:hover {

  background-color: #7f3584;
  color: whitesmoke;
  cursor: pointer;
  opacity: 0.7;

}

.searchheader-microchip-cta-button {

  margin: 0 auto;
  margin-top: 50px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  text-decoration: none;
  background-color: #7F3584;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;

}

.searchheader-microchip-cta-button:hover {

  background-color: white;
  color: #7F3584;

}

.searchheader-microchip-cta-button-alt {

  background-color: white;
  color: #7F3584;

}

.searchheader-microchip-cta-button-alt {


  background-color: #7F3584;
  color: white;
  font-size: 16px;

}

.searchheader-microchip-take-image {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 30%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.searchheader-microchip-take-image:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.search-header-progress {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  width: 50%;
  background-color: #7F3584;
  overflow: hidden;
  border-radius: 24px;
  color: white;
  position: relative;
  text-align: center;
  color: white;
  height: 41px;

}

.search-header-progress p {

  color: #7F3584;
  text-transform: uppercase;
  font-size: 14px;

}

.search-header-progress-bar {

  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  background-color: #FAFAFA;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 6px;
  overflow: hidden;
  border-radius: 24px;

}

@media(max-width: 900px) {

  .search-header-progress {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 70%;
    clear: both;
  }

  .searchheader-hero {
  }
  
  .searchheader-home-headline {

    font-size: 24px;

  }

  .searchheader-hero-home {
    padding: 0;
  }
  
  .searchheader-hero-intro {
    margin: 0 auto;
    float: none;
    width: 80%;
  }

  .searchheader-hero-logo {
    display: none;
  }

  .searchheader-hero svg {
    display: none;
  }

  .searchheader-section-column {
    margin: 0 auto;
    float: none;
    width: 80%;
  }

  .searchheader-section-column-desktop {
    display: none;
  }

  .searchheader-microchip-search-home {
    width: 75%;
  }

  .searchheader-microchip-search-home input {
    width: 101%;
    padding: 15px;
  }

  .searchheader-microchip-search-home button {
    width: 100%;
    padding: 15px 0;
    position: relative;
  }

  .searchheader-microchip-search-center {

    width: 70%;

  }

}