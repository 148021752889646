.groupsettings {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupsettings-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 130px;
  display: block;

}

.groupsettings-inner .details-box {

  margin-top: -240px;

}