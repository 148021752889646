.app {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.app-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.app-hero {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
}

.app-map {

  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0;
  display: block;
  position: relative;
  height: 64px;

}

.app .details-box {
  
  min-height: 400px;

}

.app .app-text {

  padding: 50px;

}

.app-heading {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 28px;
  line-height: 1.4;

}

.app-desc {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.4;

}

.app-actions {

  margin: 0;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.app-action {

  margin: 0;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  border: 1px solid darkgray;
  overflow: hidden;
  border-radius: 16px;
  background-color: white;

}

.app-action:hover {
  opacity: 0.7;
}

.app-action-icon {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 20%;
  float: left;

}

.app-action-icon img {

  margin: 0 auto;
  margin-top: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 50%;

}

.app-action-icon span.fas {

  margin-top: 5px;
  text-align: center;
  display: block;
  font-size: 26px;
  color: #7F3584;

}

.app-action-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 80%;
  float: left;

}

.app-action-meta > h4 {

  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;

}

.app-action-meta > p {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;

}