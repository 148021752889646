.database {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.database-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.healthcheck-message {

  margin: 42px auto;
  padding: 0;
  display: table;
  box-sizing: border-box;

  color: white;
  text-align: center;
  font-size: 18px;

  max-width: 80%;
  line-height: 1.4;

  text-transform: uppercase;
}

.database-hero {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
}

.database-map {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  height: 400px;
  width: 100%;

}

.database .details-box {

  min-height: 400px;

}

.database .database-text {

  padding: 50px;

}

.database-heading {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 28px;
  line-height: 1.4;

}

.database-desc {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.4;

}

.database-prefixes {

  margin: 15px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 15px;
  line-height: 1.65;
  color: #7F3584;
  font-weight: bold;

}

.database-engines {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.database-engine {

  margin: 0;
  margin-top: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.database-engine-inner {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  border: 1px solid darkgray;
  overflow: hidden;
  border-radius: 4px;
  background-color: white;
  text-decoration: none;

}

.database-engine-inner:hover {

  opacity: 0.8;

}

.database-engine-heading {

  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;

}

.database-engine-slogan {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  color: gray;

}

.database-engine-desc {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4;

}

.database-content-heading {

  margin: 0;
  margin-top: 20px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 19px;

}

.database-actions {

  margin: 0;
  margin-top: 20px;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.database-action {

  margin: 0;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  border: 1px solid darkgray;
  overflow: hidden;
  border-radius: 16px;
  background-color: white;

}

.database-action:hover {
  opacity: 0.7;
}

.database-action-icon {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 20%;
  float: left;

}

.database-action-icon img {

  margin: 0 auto;
  margin-top: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 50%;

}

.database-action-icon span.fas {

  margin-top: 5px;
  text-align: center;
  display: block;
  font-size: 26px;
  color: #7F3584;

}

.database-action-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 80%;
  float: left;

}

.database-action-meta>h4 {

  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;

}

.database-action-meta>p {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;

}


.database-listing-items {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  width: 90%;

}

.database-listing-heading {

  margin: 35px auto;
  margin-top: 50px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  max-width: 60%;
  text-align: center;
  font-size: 18px;

}

@media(max-width: 900px) {

  .database-listing-heading {

    max-width: 90%;
    font-size: 16px;

  }

  .healthcheck-message {

    font-size: 15px;

  }

}