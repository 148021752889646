.sent-page {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.sent-inner {

  margin: 0;
  padding: 150px 0;
  display: block;
  background-color: #FAFAFA;

}

.sent-page .iconpreview {

  margin: 0;
  padding: 0;
  display: block;

  margin: 0 auto;
  margin-bottom: 10px;
  display: table;
  text-align: center;
  font-size: 128px;

  color: #7F3584;

}

.sent-page .sentheading {

  margin: 0;
  padding: 0;
  display: block;

  margin: 0 auto;
  margin-bottom: 15px;
  display: table;
  text-align: center;
  font-size: 46px;

}

.sent-page .sentslogan {

  margin: 0;
  padding: 0;
  display: block;

  margin: 0 auto;
  display: table;
  width: 80%;
  text-align: center;
  font-size: 24px;
  line-height: 1.4;

}

@media(max-width: 900px) {

  .sent-inner {

    padding: 200px 40px;

  }

}