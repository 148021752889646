.databases {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.databases-inner {

  margin: 0;
  padding: 30px 0;
  display: block;

}

.databases-map {

  width: 100%;
  height: 750px;

}

.databases-marker {

  margin: 0;
  padding: 5px;
  display: block;
  box-sizing: border-box;
  background-color: white;

}

.databases-marker-logo {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 32px;

}

.databases-marker-name {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 9px;

}

.databases-hero {

  margin: 0;
  padding: 50px 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: #7F3584;
  min-height: 150px;

}

.databases-hero-heading {

  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  font-size: 28px;

}

.databases-hero-slogan {

  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  font-size: 17px;

}

.databases-hero-world {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 70%;

}

.databases-search-form {

  margin: 0 auto;
  padding: 10px 15px;
  display: table;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 16px;
  background-color: white;
  border: 1px solid whitesmoke;
  position: relative;

}

.databases-search-form-input {

  margin: 0;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 0;

}

.databases-listing-items {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  width: 100%;

}

.databases-listing-header {

  margin: 50px auto;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 28px;
  color: #7F3584;

}

.databases-listing-desc {

  margin: 50px auto;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  color: 'darkgray';
  font-weight: 100;

}

.databases .databases-providers-heading {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  padding: 10px 0;
  text-align: center;
  color: #7F3584;
  font-weight: bold;
}

.databases-providers-pills {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.databases-providers-pill {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;

}

.databases-providers-pill img {

  height: 32px;

}

@media(max-width: 900px) {

  .databases-listing-item {

    margin: 0 auto;
    margin-bottom: 35px;
    width: 80%;
    float: none;

  }

}