.grouprecord-history-ctas {

  margin: 0 auto;
  margin-top: 40px;
  padding: 0;
  display: table;
  box-sizing: border-box;

}

.grouprecord-history-cta {

  margin: 0 5px;
  padding: 8px 15px;
  display: block;
  box-sizing: border-box;
  float: left;

  border: 1px solid white;
  border-radius: 6px;
  background-color: #7F3584;
  text-decoration: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #7F3584;

}

.grouprecord-history-cta:hover {

  background-color: white;
  border: 1px solid #7F3584;
  color: #7F3584;

}

.grouprecord-history-listing-items {

  margin: 30px auto;
  padding: 0;
  display: table;
  width: 70%;

}

.grouprecord-history-listing-item {

  margin: 0;
  padding: 10px 0;
  display: block;
  box-sizing: border-box;

}

.grouprecord-history-listing-item-inner {

  margin: 0;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid lightgray;
  overflow: hidden;
  border-radius: 4px;

}

.grouprecord-history-listing-item-message {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 14px;

}

.grouprecord-history-listing-item-performedbyuser {
  
  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 10px;

}

.grouprecord-history-listing-item-timestamp {
  
  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 10px;

}