.app-wrapper {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow-x: hidden;

}

.wrapper {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: #F9FAFC;
  background-color: #F0F1F4;
  background-color: #F0F1F4;

}

.container {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  max-width: 900px;
}

.wrapper-alt {
  background-color: #7F3584;
}

.wrapper-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: white;

}

.banner {

  margin: 0;
  padding: 23px 30px;
  display: block;
  box-sizing: border-box;
  clear: both;
  border: 1px solid #ffeeba;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  overflow: hidden;

  color: #856404;
  background-color: #faed85;
  text-transform: uppercase;
  position: relative;
  line-height: 1.7;

}

.banner-progress {

  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;

}

.banner .progress {

  margin: 0;
  margin-top: -1px;
  padding: 0;
  display: block;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 0%;
  background-color: red;

}

.banner .text {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  clear: both;
  max-width: 65%;
  text-align: center;

  color: #856404;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

}

.banner-hero {

  margin-bottom: -10px;

}

.progress-overlay {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  clear: both;
  background-color: #7F3584;
  opacity: 0.92;
  
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40000;

}

.progress-wrapper {

  margin: 0;
  padding: 80px 0;
  display: block;
  box-sizing: border-box;
  clear: both;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50000;

}

.progress-text {
  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;  
  color: white;
  text-align: center;
}

.progress-box-container {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: 0;
  width: 50%;

}

.progress-box {
  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;  
  width: 50px;
  height: 50px;
}

.progress-box:before {
  content: '';
  width: 50px;
  height: 5px;
  background: white;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow .5s linear infinite;
}

.progress-box:after {
  content: '';
  width: 50px;
  height: 50px;
  background: white;
  animation: animate .5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}


@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}

@media(max-width: 900px) {
  .banner .text {
    max-width: 90%;
  }
}