.login {

  margin: 0;
  padding: 0;
  display: block;

}

.login-inner {

  margin: 0;
  padding: 90px 0;
  padding-bottom: 60px;
  padding-bottom: 140px;
  display: block;

}

.login-icon {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  text-align: center;
  font-size: 46px;
  color: white;

}

.login-heading {

  margin: 0;
  padding: 0;
  display: block;
  text-align: center;
  color: white;
  font-size: 49px;

}

.login-slogan {

  margin: 15px 0;
  padding: 0;
  display: block;
  text-align: center;
  color: #111;
  font-size: 18px;
  text-transform: uppercase;

}

.login-form {

  margin: 0 auto;
  padding: 25px 20px;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

}

.login-form-control {

  margin: 0;
  padding: 8px 15px;
  display: block;
  clear: both;

}

.login-form-control label {

  margin: 0;
  padding: 5px 0;
  padding-bottom: 15px;
  display: block;
  box-sizing: border-box;
  font-size: 17px;
  text-transform: uppercase;
  color: #666;
  width: 100%;

}

.login-form-control input {

  margin: 0;
  padding: 15px 15px;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  overflow: hidden;
  border: 1px solid whitesmoke;
  border-radius: 4px;

}

.login-form-control input:hover {

  border-color: gray;

}

.login-form-control a {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  float: left;
  font-size: 14px;
  text-decoration: none;

}

.login-form-control a:hover {

  text-decoration: underline;

}

.login-form-control button {

  margin: 0;
  padding: 5px 10px;
  display: block;
  float: right;
  background-color: #7F3584;
  border: 1px solid #7F3584;
  font-size: 18px;
  color: white;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;

}

.login-form-control button:hover {

  background-color: white;
  color: #53AE82;

}

.login-dialog {

  margin: 0 auto;
  margin-top: 60px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #FAFAFA;
  width: 50%;
  border-radius: 6px;
  border: 1px solid white;

}

@keyframes tail {
  6.6666666667% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(10deg);
  }
  16.6666666667% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(30deg);
  }
  26.6666666667% {
    transform: rotate(-2deg);
  }
  46.6666666667% {
    transform: rotate(10deg);
  }
  53.3333333333% {
    transform: rotate(-5deg);
  }
  56.6666666667% {
    transform: rotate(10deg);
  }
}
@keyframes body {
  6.6666666667% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(1.15);
  }
  16.6666666667% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(1.25);
  }
  26.6666666667% {
    transform: scaleY(1);
  }
  46.6666666667% {
    transform: scaleY(1.15);
  }
  53.3333333333% {
    transform: scaleY(1);
  }
  56.6666666667% {
    transform: scaleY(1.15);
  }
}
@keyframes left-whisker {
  6.6666666667% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(0deg);
  }
  16.6666666667% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  26.6666666667% {
    transform: rotate(0deg);
  }
  46.6666666667% {
    transform: rotate(10deg);
  }
  53.3333333333% {
    transform: rotate(-5deg);
  }
  56.6666666667% {
    transform: rotate(10deg);
  }
}
@keyframes right-whisker {
  6.6666666667% {
    transform: rotate(180deg);
  }
  10% {
    transform: rotate(190deg);
  }
  16.6666666667% {
    transform: rotate(180deg);
  }
  20% {
    transform: rotate(175deg);
  }
  26.6666666667% {
    transform: rotate(190deg);
  }
  46.6666666667% {
    transform: rotate(180deg);
  }
  53.3333333333% {
    transform: rotate(185deg);
  }
  56.6666666667% {
    transform: rotate(175deg);
  }
}
@keyframes left-ear {
  0% {
    transform: rotate(-20deg);
  }
  6.6666666667% {
    transform: rotate(-6deg);
  }
  13.3333333333% {
    transform: rotate(-15deg);
  }
  26.6666666667% {
    transform: rotate(-15deg);
  }
  33.3333333333% {
    transform: rotate(-30deg);
  }
  40% {
    transform: rotate(-30deg);
  }
  46.6666666667% {
    transform: rotate(0deg);
  }
  53.3333333333% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  93.3333333333% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotateZ(-6deg);
  }
}
@keyframes right-ear {
  0% {
    transform: rotateZ(-16deg);
  }
  6.6666666667% {
    transform: rotateZ(-16deg);
  }
  13.3333333333% {
    transform: rotateZ(-19deg);
  }
  26.6666666667% {
    transform: rotateZ(-19deg);
  }
  33.3333333333% {
    transform: rotateZ(-30deg);
  }
  36.6666666667% {
    transform: rotateZ(-19deg);
  }
  37.3333333333% {
    transform: rotateZ(-30deg);
  }
  38% {
    transform: rotateZ(-19deg);
  }
  40% {
    transform: rotateZ(-19deg);
  }
  40.6666666667% {
    transform: rotateZ(-30deg);
  }
  41.3333333333% {
    transform: rotateZ(-19deg);
  }
  46.6666666667% {
    transform: rotateZ(-9deg);
  }
  53.3333333333% {
    transform: rotateZ(-9deg);
  }
  60% {
    transform: rotateZ(-19deg);
  }
  60.6666666667% {
    transform: rotateZ(-30deg);
  }
  61.3333333333% {
    transform: rotateZ(-19deg);
  }
  62.6666666667% {
    transform: rotateZ(-19deg);
  }
  63.3333333333% {
    transform: rotateZ(-30deg);
  }
  64% {
    transform: rotateZ(-19deg);
  }
  80% {
    transform: rotateZ(-19deg);
  }
  93.3333333333% {
    transform: rotateZ(-16deg);
  }
  100% {
    transform: rotateZ(-16deg);
  }
}

.login .cat-box {

  background-color: #abe7db;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.login .cat {
  width: 110px;
  height: 50px;
  position: absolute;
  top: -50px;
  right: 0px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.login .cat .body {
  width: 110px;
  height: 50px;
  background-color: #333;
  position: absolute;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  animation: body 12s none infinite;
}
.login .cat .head {
  content: "";
  width: 70px;
  height: 35px;
  background-color: #333;
  position: absolute;
  top: calc(50% - 10px);
  left: -40px;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.login .tail-container {
  position: absolute;
  right: 0;
  bottom: -13px;
  z-index: 3;
}

.login .tail {
  position: absolute;
  height: 30px;
  width: 14px;
  bottom: -10px;
  right: 0;
  border-bottom-right-radius: 5px;
  background: #333;
  z-index: 0;
}
.login .tail > .tail {
  animation: tail 12s none infinite;
  height: 100%;
  width: 14px;
  transform-origin: left;
  border-bottom-left-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-top-right-radius: 40px;
}

.login .ear {
  position: absolute;
  left: 4px;
  top: -4px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #333;
  transform: rotate(-30deg);
  animation: left-ear 12s both infinite;
}
.login .ear + .ear {
  animation: right-ear 12s both infinite;
  top: -12px;
  left: 30px;
}

.login .nose {
  position: absolute;
  bottom: 10px;
  left: -10px;
  background-color: #fd6e72;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.login .whisker-container {
  position: absolute;
  bottom: 5px;
  left: -36px;
  width: 20px;
  height: 10px;
  transform-origin: right;
  animation: left-whisker 12s both infinite;
}
.login .whisker-container:nth-child(2) {
  left: -20px;
  bottom: 12px;
  transform-origin: right;
  transform: rotate(180deg);
  animation: right-whisker 12s both infinite;
}

.login .whisker {
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid #fdf9de;
  transform-origin: 100% 0;
  transform: rotate(10deg);
}
.login .whisker:last-child {
  top: 0;
  transform: rotate(-20deg);
}

@media(max-width: 900px) {

  .login-dialog {

    width: 80%;

  }
  
}
