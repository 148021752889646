.groupoverview {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupoverview-items-blank {

  margin: 0;
  padding: 0;
  padding-bottom: 80px;
  display: block;
  box-sizing: border-box;

}

.groupoverview-items-blank .fas {

  margin: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 164px;
  color: #7F3584;

}

.groupoverview-items-blank p {

  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 21px;
  line-height: 1.4;

}

.groupoverview-datasets {

  margin: 0 auto;
  margin-bottom: 45px;
  padding: 0;
  display: table;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;
  border-radius: 6px;
  border: 1px solid #7F3584;
  overflow: hidden;

}

.groupoverview-dataset {

  margin: 0;
  padding: 10px 20px;
  display: block;
  position: relative;
  box-sizing: border-box;
  float: left;
  color: white;
  text-decoration: none;
  border: 0;

}

.groupoverview-dataset:hover, .groupoverview-dataset.active {

  background-color: white;
  color: #7F3584;

}

.groupoverview .details-box {

  margin-top: -300px;

}

.groupoverview-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.groupoverview-meta-map {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  height: '50vh';
  width: '100%';
  border-top: 1px solid white;
  border-bottom: 1px solid white;

}

.groupoverview-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;

}

.groupoverview .groupoverview-columns {

  margin: 0;
  padding: 25px;
  display: block;
  position: relative;
  box-sizing: border-box;

}

.groupoverview .groupoverview-column {

  margin: 0;
  padding: 0;
  padding-top: 35px;
  display: block;
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 33.3%;
  height: 180px;
  overflow: hidden;

}

.groupoverview .groupoverview-column-padding-top {
  
  padding-top: 50px;

}

.groupoverview .groupoverview-column-bottom {
  
  border-bottom: 1px solid lightgray;

}

.groupoverview .groupoverview-column-left {
  
  border-left: 1px solid lightgray;

}

.groupoverview .groupoverview-column-right {
  
  border-right: 1px solid lightgray;

}

.groupoverview .groupoverview-column .groupoverview-column-icon {
 
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupoverview .groupoverview-column .groupoverview-column-icon img {

  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  height: 32px;
  overflow: hidden;
  border-radius: 6px;

}

.groupoverview .groupoverview-column .groupoverview-column-icon .fas, .groupoverview .groupoverview-column .groupoverview-column-icon .fab {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 28px;
  color: #7F3584;

}

.groupoverview .groupoverview-column .groupoverview-column-label {
 
  margin: 0;
  padding: 8px;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupoverview .groupoverview-column .groupoverview-column-label h4 {
 
  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 23px;
  color: #666;
  max-width: 60%;
    
}

.groupoverview .groupoverview-column .groupoverview-column-label p {
 
  margin: 0 auto;
  margin-top: 8px;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #666;
  max-width: 60%;
  line-height: 1.4;
    
}

.groupoverview .groupoverview-column .groupoverview-column-desc {
 
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
    
}

.groupoverview .groupoverview-column .groupoverview-column-desc p {
 
  margin: 0 auto;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  font-size: 11px;
  color: darkgray;
  max-width: 60%;
  line-height: 1.4;
    
}