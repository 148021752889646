.footer {

  margin: 0;
  padding: 15px 0;
  padding-bottom: 60px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  
}

.footer-alt {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #3c4859;
  text-decoration: none;
  cursor: pointer;
  
}

.footer-links {

  margin: 0 auto;
  padding: 0;
  display: table;

}

.footer-locality {

  margin: 0 auto;
  margin-top: 35px;
  padding: 0;
  display: table;

}

.footer-locality-flag {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  height: 14px;

}

.footer-locality-text {

  margin: 0;
  margin-left: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  color: white;
  font-size: 14px;

}

.footer-links-item {

  margin: 0;
  padding: 25px;
  display: block;
  float:  left;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  
}

.footer-links-item-icon {

  margin: 0;
  margin-right: 3px;
  padding: 0;
  display: block;
  float:  left;
  height: 32px;

}

.footer-links-item-label {

  margin: 0;
  padding: 0;
  padding-top: 5px;
  display: block;
  float:  left;
  font-size: 18px;

}

.footer-links-item:hover {

  background-color: whitesmoke;

}

.footer-copyright {

  margin: 35px 0;
  padding: 0;
  display: block;
  text-align: center;
  color: white;
  font-size: 16px;

}

.footer-copyright .fas {

  margin-left: 5px;
  margin-right: 5px;
  /* color: #53AE82; */
  color: #E5BEED;
  color: #7f3584;
  color: white;

}

.footer-links {

  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  display: table;

}

.footer-links li {

  margin: 0 10px;
  padding: 0;
  display: block;
  float: left;

}

.footer-links li a {

  margin: 0;
  padding: 0;
  display: block;
  color: white;
  text-decoration: none;
  font-size: 13px;

}

.footer-links li a:hover {

  text-decoration: underline;

}

.footer-links {

  margin-top: 30px;

}

.footer-links-social a span {

  font-size: 24px;
  color: white;

}

.footer-links-social a:hover {

  opacity: 0.8;

}