.animal {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.animal-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.animal-hero {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #22B054;
}