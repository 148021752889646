.initialoading-wrapper {

  margin: 0;
  padding: 140px 0;
  box-sizing: border-box;
  display: block;

}

.initialoading-wrapper-bg {

  background-color: #7F3584;  

}

.initialoading-inside-wrapper {

  margin: 0;
  padding: 100px 0;
  box-sizing: border-box;
  display: block;

}

.initialoading-heading {

  margin: 0;
  margin-top: 25px;
  margin-left: -20px;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 16px;

}

.initialoading-extra-info {

  margin: 0;
  margin-top: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-size: 12px;

}

.initialoading-preview-image {

  margin: 0 auto;
  margin-bottom: -44px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  height: 256px;

}

.initialoading-preview-message {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: white;
  text-align: center;
  max-width: 50%;
  line-height: 1.65;

}

.initialoading-preview-cta {
  
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  background-color: white;
  text-decoration: none;
  color: #7F3584;
  font-size: 21px;
  cursor: pointer;

}

.initialoading-preview-cta:hover {

  background-color: #7F3584;
  color: white;

}

.initialoading .dots{
  width:146px;
	margin: 0 auto;
  margin-top: 0px;
}

.initialoading .dot{
  background-color: white;
  width: 20px;
  height: 30px;
  margin-right: 3px;
  border-radius: 50%;
  animation-name: initialdogload;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

.initialoading .dot5{
  background-color: white;
  margin-top: 15px;
  width: 10px;
  height: 18px;
  border-radius: 50%;
}

.initialoading .dot1,
.initialoading .dot2, 
.initialoading .dot3, 
.initialoading .dot4{
  display: inline-block;
}

.initialoading .dot2, 
.initialoading .dot3{
  width: 30px;
  height: 40px
}

.initialoading .dot3 {
  margin-left: 5px;
}

.initialoading .dot1, 
.initialoading .dot4{
  position: relative;
  top: 20px
}

.initialoading .dot1{
  animation-delay:0.1s;
}

.initialoading .dot2{
  animation-delay:0.2s;
}

.initialoading .dot3{
  animation-delay:0.3s;
}

.initialoading .dot4{
  animation-delay:0.4s;
}

.initialoading .dot5{
  width: 80px;
  height: 70px;
  margin-top: 8px;
  margin-left: 15px;
}

@keyframes initialdogload {
    0% {visibility: visible;}
    25% {visibility: visible;}
    75% {visibility: hidden;}
    100% {visibility: hidden;}
}