.docs {

  margin: 0;
  padding: 50px 0;
  padding-top: 15px;
  padding-bottom: 80px;
  display: block;
  background-color: #7F3584;

}

.docs-apis {

  margin: 0;
  padding: 15px 0;
  box-sizing: border-box;
  display: block;

}

.docs-api {

  margin: 0;
  margin-bottom: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-decoration: none;

}

.docs-api .docs-api-link {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: right;
  color: gray;
  font-size: 11px;

}

.docs-api h3 {

  margin: 0;
  margin-bottom: 6px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  color: #7F3584;

}

.docs-api p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  color: #666;
  line-height: 1.6;

}

.docs-api-inner {

  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  border-radius: 6px;
  background-color: white;
  border-bottom: 5px solid #7F3584;
  text-decoration: none;

}

.docs-hero {

  margin: 0;
  padding: 100px 0;
  position: relative;

}

.docs-content {

  margin: 0;
  padding: 15px 25px;
  box-sizing: border-box;
  display: block;

}

.docs-heading {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 26px;
  line-height: 1.5;

}

.docs-subheading {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 21px;
  line-height: 1.5;

}

.docs-text {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 15px;
  line-height: 1.8;

}

.docs-image-full {

  margin: 45px auto;
  display: table;
  width: 50%;

}

.docs-codeblock {

  margin: 0;
  padding: 25px 30px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: #7F3584;
  font-size: 14px;
  color: white;
  line-height: 1.5;

}

.docs-content code {

  margin: 0 5px;
  padding: 4px 10px;
  background-color: white;
  border: 1px solid white;
  overflow: hidden;
  border-radius: 6px;

}

.docs-table {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid darkgray;
  border-bottom: 0;
  width: 100%;

}

.docs-table tbody, .docs-table thead {

  width: 100%;

}

.docs-table th {

  padding: 10px 8px;
  color: #222;
  font-weight: bold;
  line-height: 1.4;

}

.docs-table tr {

  width: 100%;
  border-bottom: 1px solid darkgray;

}

.docs-table td {

  padding: 10px 8px;
  color: #666;
  font-size: 14px;
  line-height: 1.6;

}

.docs-table td.important {

  color: #222;
  font-weight: bold;

}