.rejected-page {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.rejected-inner {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}


@media(max-width: 900px) {

  .rejected-inner {

    padding: 50px 40px;

  }

}