.groupimportadd {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupimportadd-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.groupimportadd-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;

}

.groupimportadd .details-box {

  margin-top: -250px;

}

.groupimportadd-blank {

  margin: 0;
  padding: 30px;
  display: block;
  box-sizing: border-box;

}

.groupimportadd-blank-inner {

  margin: 0;
  padding: 0;
  padding-bottom: 80px;
  display: block;
  box-sizing: border-box;
  border: 1px solid lightgray;
  overflow: hidden;
  border-radius: 12px;

}

.groupimportadd-blank-inner:hover {

  cursor: pointer;
  background-color: white;

}

.groupimportadd-blank .fas {

  margin: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 164px;
  color: #7F3584;

}

.groupimportadd-blank p {

  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 19px;
  line-height: 1.4;

}

@media(max-width: 900px) {

  .groupimportadd-blank {
    padding: 0;
  }

}