.groupnew {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groupnew-inner {

  margin: 0;
  padding: 150px 0;
  display: block;

}

.groupnew-hero {

  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #7F3584;

}

.groupnew .details-box {

  margin-top: -250px;

}