.add {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;
  background-color: #7F3584;

}

.add-inner {

  margin: 0;
  padding: 80px 0;
  display: block;

}

.add-alt {
  background-color: #FAFAFA;
}

.add-microchip-package {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 50%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.add-microchip-package-inner {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  user-select: none;
}

.add-microchip-package-barcode {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  user-select: none;
}

.add-microchip-package-barcode-text {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #111;
  outline: 0;

}

.add-microchip-heading {

  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 50%;
  font-size: 21px;

}

.add-microchip-slogan {

  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 45%;
  font-size: 13px;
  line-height: 1.5;

}

.add-microchip-tagline {

  margin: 0 auto;
  margin-top: 25px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 45%;
  font-size: 13px;
  line-height: 1.5;

}

.add-microchip-divider {

  margin: 25px auto;
  padding: 0;
  display: table;
  text-align: center;
  background-color: white;
  box-sizing: border-box;
  height: 1px;
  width: 10%;

}

.add-microchip-barcode-video {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 80%;
  max-width: 300px;
  background-color: white;
  border-radius: 3px;

}

.add-microchip-search {

  margin: 0 auto;
  padding: 0;
  display: table;
  width: 50%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;

}

.add-microchip-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 75%;
  border: 0;
  font-size: 21px;
  color: #111;
    
}

.add-microchip-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 25%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: #111;
  color: white;
    
}

.add-microchip-search button:hover {

  background-color: #7f3584;
  color: white;
  cursor: pointer;

}

.add-microchip-cta-button {

  margin: 0 auto;
  margin-top: 50px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  text-decoration: none;
  background-color: #7F3584;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;

}

.add-microchip-cta-button:hover {

  background-color: white;
  color: #7F3584;

}

.add-microchip-cta-button-alt {

  background-color: white;
  color: #7F3584;

}

.add-microchip-cta-button-alt {


  background-color: #7F3584;
  color: white;
  font-size: 16px;

}

.add-microchip-take-image {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 30%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.add-microchip-take-image:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.add-microchip-take-image-instructions {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  line-height: 1.3;
  max-width: 75%;
  text-align: center;
  font-size: 13px;

}

.add-microchip-take-image img {
  width: 100%;
}

.add-microchip-portraits {

  margin: 0 auto;
  padding: 0;
  max-width: 30%;
  box-sizing: border-box;
  display: table;

}

.add-microchip-portrait {
  margin: 0;
  margin-right: 15px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.add-microchip-portrait img {
  height: 128px;
  opacity: 1;
}

.add-microchip-portrait:hover img {
  opacity: 0.8;
}

.add-hero {

  margin: 0;
  padding: 0;
  padding-top: 40px;
  box-sizing: border-box;
  display: block;
  height: 250px;
  z-index: 1000;
  background-color: #7F3584;

}

.add-hero-heading {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 21px;

}

.add-hero-slogan {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 16px;

}

.add-details-box {

  margin: 0 auto;
  margin-top: -200px;
  z-index: 5000;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 90%;
  border-radius: 6px;
  background-color: whitesmoke;
  border: 3px solid #7F3584;
  border: 1px solid white;
  overflow: hidden;
  box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);

}

.add-details-box-preview {

  margin: 0 auto;
  padding: 60px 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border-right: 1px solid #FAFAFA;

}

.add-details-box-preview p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-align: center;

}

.add-details-box-preview img {

  margin: 0 auto;
  padding: 25px;
  box-sizing: border-box;
  display: block;
  width: 80%;
  
}

.add-details-box-meta {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: right;
  width: 65%;

}

.add-details-box-toolbar {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  background-color: #a244a8;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;

}

.add-details-box-toolbar-text {

  margin: 0;
  margin-top: 3px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  color: white;
  font-size: 17px;

}

.add-details-box-toolbar-action {

  margin: 0;
  margin-left: 10px;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
  text-transform: uppercase;
  background-color: lightpink;
  color: #666;
  text-decoration: none;
  border-color: 1px solid #7F3584;
  border-radius: 6px;
  overflow: hidden;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 100;

}

.add-details-box-toolbar-action:hover {
  background-color: white;
  color: #7F3584;
}

.add-details-box-toolbar-action-right {
  float: right;
}

.add-details-box-toolbar-action-left {
  float: left;
}

.add-details-box-controls {

  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  display: block;

}

.add-details-box-control {

  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;

}

.add-details-box-control-half {

  float: left;
  width: 50%;

}

.add-details-box-control-label {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.add-details-box-control-label label {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.add-details-box-control-label p {

  margin: 0;
  padding: 0;
  padding-top: 8px;
  box-sizing: border-box;
  display: block;
  float: right;
  font-size: 11px;
  text-transform: uppercase;

}

.add-details-box-control-inputs {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  margin-top: 10px;

}

.add-details-box-control-inputs input {

  margin: 0;
  padding: 11px 15px;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  width: 100%;
  border: 1px solid lightgray;

}

.add-details-box-control-inputs input[type=date] {
  font-size: 14px;
}

.add-details-box-control-selectables {

  margin: 0;
  padding: 10px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: block;
  background-color: white;
  border: 1px solid lightgray;

}

.add-details-box-control-selectable {

  margin: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  float: left;
  border-radius: 6px;
  background-color: whitesmoke;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.6;

}

.add-details-box-control-selectable:hover {
  
  opacity: 1;

}

.add-details-box-control-selectable.active {

  background-color: #7F3584;
  color: white;
  opacity: 1;

}

@media(max-width: 900px) {

  .add-microchip-heading {
    max-width: 90%;
    font-size: 20px;
    line-height: 1.1;
  }

  .add-microchip-slogan, .add-microchip-tagline {

    max-width: 80%;
    font-size: 16px;

  }

  .add-microchip-package {
  
    width: 80%;
  
  }

  .add-inner {
    padding: 50px 0;
  }

  .add-microchip-search {
    width: 80%;
  }

  .add-microchip-take-image {

    width: 75%;

  }

}