.cta-button {

  margin: 0 auto;
  margin-top: 50px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  text-decoration: none;
  background-color: #7F3584;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;

}

.cta-button:hover {

  background-color: white;
  color: #7F3584;

}

.cta-button-alt {

  background-color: white;
  color: #7F3584;

}

.cta-button-alt {


  background-color: #7F3584;
  color: white;
  font-size: 16px;

}

.hero {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  z-index: 1000;
  background-color: #7F3584;

}

.hero-box {

  margin: 0;
  padding: 0;
  padding-top: 40px;
  box-sizing: border-box;
  display: block;
  height: 250px;
  z-index: 1000;
  background-color: #7F3584;

}

.hero-heading {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 21px;

}

.hero-slogan {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 16px;

}

.details-box-meta-textcontent {

  margin: 0;
  padding: 25px 25px;
  box-sizing: border-box;
  display: block;

}

.details-box-meta-textcontent>h1 {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}

.details-box-meta-textcontent>h2 {
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
}

.details-box-meta-textcontent>h3 {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}

.details-box-meta-textcontent>ul {
  margin-top: 10px;
  margin-bottom: 25px;
  line-height: 1.5;
}

.details-box-meta-textcontent>p {
  font-size: 16px;
  margin: 10px 0;
  line-height: 1.5;
}

.details-box {

  margin: 0 auto;
  margin-top: -200px;
  z-index: 5000;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 90%;
  border-radius: 6px;
  background-color: whitesmoke;
  border: 3px solid #7F3584;
  border: 1px solid white;
  overflow: hidden;
  box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);

}

.details-box-top {

  margin-top: 30px;

}

.details-box-sidebar {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border-right: 1px solid #FAFAFA;

}

.details-box-preview {

  margin: 0 auto;
  padding: 60px 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border-right: 1px solid #FAFAFA;

}

.details-box-preview>p,
.details-box-preview .details-box-preview-text {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-align: center;
  max-width: 70%;
  line-height: 1.6;
  font-size: 13px;

}

.details-box-preview>img,
.details-box-preview .details-box-preview-img {

  margin: 0 auto;
  padding: 25px;
  box-sizing: border-box;
  display: block;
  width: 80%;

}

.details-box-preview:hover {

  opacity: 0.9;
  cursor: pointer;

}

.details-box-meta {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: right;
  width: 65%;

}

.details-box-meta-column {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;

}

.details-box-meta-full {

  width: 100%;

}

.details-box-toolbar {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  background-color: #a244a8;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;

}

.details-box-toolbar-bad {

  background-color: #9d0208;

}

.details-box-toolbar-text {

  margin: 0;
  margin-top: 3px;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.5;
  display: block;
  float: left;
  color: white;
  font-size: 17px;

}

.details-box-toolbar-text>span.fas {

  margin-right: 10px;

}

.details-box-toolbar-bad .details-box-toolbar-text {

  text-transform: uppercase;

}

.details-box-toolbar-action {

  margin: 0;
  margin-left: 10px;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
  text-transform: uppercase;
  background-color: white;
  color: #7F3584;
  text-decoration: none;
  border-color: 1px solid #7F3584;
  border-radius: 6px;
  overflow: hidden;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 100;

}

.details-box-toolbar-action:hover {
  background-color: white;
  color: #7F3584;
}

.details-box-toolbar-action-right {
  float: right;
}

.details-box-toolbar-action-left {
  float: left;
}

.details-box-controls {

  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  display: block;

}

.details-box-control {

  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  display: block;

}

.details-box-control-half {

  float: left;
  width: 50%;

}

.details-box-control-label {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.details-box-control-label label {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.details-box-control-label p {

  margin: 0;
  padding: 0;
  padding-top: 8px;
  box-sizing: border-box;
  display: block;
  float: right;
  font-size: 11px;
  text-transform: uppercase;

}

.details-box-control-inputs {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  margin-top: 10px;

}

.details-box-control-inputs textarea {

  margin: 0;
  padding: 11px 15px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  width: 100%;
  border: 1px solid lightgray;

}

.details-box-control-inputs input {

  margin: 0;
  padding: 11px 15px;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  width: 100%;
  border: 1px solid lightgray;

}

.details-box-control-inputs input[type=date] {
  font-size: 14px;
}

.details-box-control-selectables {

  margin: 0;
  padding: 10px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: block;
  background-color: white;
  border: 1px solid lightgray;

}

.details-box-control-selectable {

  margin: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  float: left;
  border-radius: 6px;
  background-color: whitesmoke;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.6;
  color: darkgray;
  font-weight: bold;

}

.details-box-control-selectable:hover {

  opacity: 1;

}

.details-box-control-selectable.active {

  background-color: #7F3584;
  color: white;
  opacity: 1;

}

.details-box-sidebar-menu {

  margin: 0;
  padding: 20px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;

}

.details-box-sidebar-menuitem {

  margin: 0;
  padding: 12px 20px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #7F3584;
  border-bottom: 1px solid whitesmoke;

}

.details-box-sidebar-menuitem>span {

  margin-right: 8px;

}

.details-box-sidebar-menuitem.active,
.details-box-sidebar-menuitem:hover {

  background-color: #7F3584;
  color: white;

}

.details-box-healthcheck {

  min-height: 125px !important;

}

@media(max-width: 900px) {

  .details-box {
    z-index: 200;
  }

  .details-box-preview-desktop {
    display: none;
  }

  .details-box-preview,
  .details-box-sidebar {

    width: 100%;
    position: relative;
    padding-bottom: 60px;

  }

  .details-box-preview>img,
  .details-box-preview .details-box-preview-img {

    width: 50%;

  }

  .details-box-meta {

    width: 100%;

  }

  .details-box-control-label p {

    font-size: 9px;

  }

  .details-box-toolbar {

    padding: 20px 15px;

  }

  .details-box-toolbar-bad .details-box-toolbar-text {
    text-align: center;
    font-size: 17px;
    margin: 0 auto;
    display: table;
    max-width: 80%;
    float: none;
  }

  .details-box-meta-column {

    padding: 25px;
    width: 100%;

  }

}