.verify-page {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.verify-inner {

  margin: 0;
  padding: 150px 0;
  display: block;
  background-color: #FAFAFA;

}

.verify-page .iconpreview {

  margin: 0;
  padding: 0;
  display: block;

  margin: 0 auto;
  margin-bottom: 25px;
  display: table;
  text-align: center;
  font-size: 128px;

  color: #7F3584;

}

.verify-page .verifyheading {

  margin: 0;
  padding: 0;
  display: block;

  margin: 0 auto;
  margin-bottom: 25px;
  display: table;
  text-align: center;
  font-size: 46px;

}

.verify-page .verifyslogan {

  margin: 0;
  padding: 0;
  display: block;

  margin: 15px auto;
  display: table;
  width: 55%;
  text-align: center;
  font-size: 19px;
  line-height: 1.4;

}

.verify-page .verifysiginput {

  margin: 15px auto;
  padding: 10px 20px;
  display: block;
  width: 60%;

  display: table;
  border: 0.5 solid #7F3584;
  border-radius: 6px;

  font-size: 24px;

}

.verify-page .verifycta {

  margin: 0 auto;
  padding: 10px 15px;
  display: block;

  display: table;
  background-color: #7F3584;
  color: white;
  border-radius: 6px;
  overflow: hidden;
  font-size: 21px;

  border: 1px solid #7F3584;
  cursor: pointer;

}

.verify-page .verifycta:hover {

  background-color: white;
  color: #7F3584;

}

.verify-previewpdfframe {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

  width: 100%;
  min-height: 800px;

}

@media(max-width: 900px) {

  .verify-inner {

    padding: 100px 40px;

  }

  .verify-page .verifyheading {
    font-size: 21px;
  }

  .verify-page .verifyslogan {
    width: 90%;
  }

  .verify-page .verifysiginput {
    width: 90%;
  }

}