.searchview {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.searchview-bg {

  background-color: #7F3584;

}

.searchview-busy-wrapper {

  margin: 0 auto;
  padding: 50px 0;
  display: block;
  box-sizing: border-box;
  width: 70%;

}

.searchview-busy-text {

  text-align: center;
  line-height: 1.7;

}

.searchview-busy-circle {

  margin: 30px auto;
  display: block;
  box-sizing: border-box;
  width: 30%;

}

.searchview .CircularProgressbar-path {

  stroke: #7F3584 !important;

}


.searchview .CircularProgressbar-text {

  fill: #7F3584 !important;

}

.searchview-dialog {

  margin: 0;
  padding: 90px 0;
  display: block;
  box-sizing: border-box;

}

.searchview-dialog>.fas {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 126px;
  color: white;

}

.searchview-dialog-heading {

  margin: 15px auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 24px;
  color: white;
  max-width: 50%;
  line-height: 1.3;

}

.searchview-dialog-slogan {

  margin: 15px auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: white;
  max-width: 50%;
  line-height: 1.3;

}

.searchview-research-btn {

  margin: 0;
  padding: 10px 15px;
  display: none;
  box-sizing: border-box;
  color: white;
  border: 1px solid white;
  border-radius: 26px;
  text-decoration: none;
  overflow: hidden;

  position: absolute;
  right: 30px;
  top: 80px;
  font-size: 13px;

}

.searchview-research-btn:hover {

  background-color: white;
  color: #7F3584;

}

.searchview-research-btn .fas {

  margin-right: 10px;

}

.searchview-provider-list-sidebar-red-label {

  margin-bottom: 15px;
  text-align: center;
  color: gray;
  font-size: 11px;

}

.searchview-provider-list-sidebar-tagline {

  font-size: 14px;

}

.searchview-provider-list {

  margin: 0;
  padding: 20px 0;
  display: block;
  box-sizing: border-box;

  max-height: 256px;
  overflow-y: scroll;

}

.searchview-provider-list-min {

  padding-top: 0;
  margin-bottom: 80px;
  margin-top: -10px;
  overflow: visible;

}

.searchview-provider-list-min .searchview-provider-list-item {

  border-bottom: 1px solid lightgray;
  padding-top: 20px;
  margin-bottom: 0;

}

.searchview-provider-list-min .searchview-provider-list-item:hover {
  background-color: white;
  text-decoration: none;
}

.searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-inner:hover {
  text-decoration: none;
}

.searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-preview {

  width: 15%;
  padding-top: 5px;

}

.searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-preview img {
  float: none;
  max-width: 65%;
}

.searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-meta {

  width: 85%;

}

.searchview-provider-list-item {

  margin: 0;
  margin-bottom: 5px;
  padding: 8px 16px;
  display: block;
  box-sizing: border-box;

}

.searchview-provider-list-item-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  border-bottom: 1px solid whitesmoke;

}

.searchview-provider-list-item-preview {

  margin: 0;
  padding: 0;
  padding-right: 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 30%;

}

.searchview-provider-list-item-preview img {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 52%;
  float: right;

}

.searchview-provider-list-item-meta {

  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  ;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 70%;

}

.searchview-provider-list-item-meta-name {

  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;

}

.searchview-provider-list-item-meta-desc {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 11px;
  text-align: left;

}

.searchview-footer {

  margin: 0;
  padding: 35px 0;
  display: block;
  box-sizing: border-box;

}

.searchview-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchview-hero {

  margin: 0;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  background-color: #a244a8;

}

.searchview-hero-inner {

  margin: 0;
  padding: 50px 0;
  display: block;
  box-sizing: border-box;

}

.searchview-hero-column-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchview-hero-column-preview-photo {

  margin: 0 auto;
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
  display: table;
  box-sizing: border-box;
  background-color: white;
  width: 30%;

}

.searchview-hero-column-preview-photo img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.search-add-contact-cta {

  margin: 35px 0;
  padding: 8px 16px;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  border-radius: 15px;
  text-transform: uppercase;
  background-color: #7F3584;
  text-decoration: none;

}

.searchview-provider-banner {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-decoration: none;

}

.searchview-provider-banner:hover {

  opacity: 0.8;

}

.searchview-provider-banner-inner {

  margin: 0 auto;
  padding: 40px 10px;
  box-sizing: border-box;
  display: block;
  width: 90%;

}

.searchview-provider-banner-gmk {
  background-color: #22b153;
}

.searchview-provider-banner-preview {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 13%;

}

.searchview-provider-banner-preview>img {

  margin: 0 auto;
  margin-top: 8px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  width: 50%;

}

.searchview-provider-banner-meta {

  margin: 0;
  padding: 0;
  padding-left: 20px;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 87%;

}

.searchview-provider-banner-meta>p {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

  text-align: left;
  color: white;
  line-height: 1.4;

}

.search-certificate-meta {

  margin: 0;
  padding: 25px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 55%;
  float: left;

}

.search-certificate-meta-heading {

  margin: 0;
  padding: 25px;
  padding-bottom: 0;
  font-size: 32px;
  line-height: 1.4;

}

.search-certificate-bg {

  position: absolute;
  right: -30px;
  bottom: -180px;
  height: 512px;

}

.search-certificate-cta {

  margin: 5px 0;
  margin-bottom: 5px;
  padding: 8px 16px;
  display: block;
  box-sizing: border-box;
  color: white;
  float: left;
  border-radius: 6px;
  text-transform: uppercase;
  background-color: #7F3584;
  border: 1px solid #7F3584;
  text-decoration: none;
  font-size: 23px;

}

.search-certificate-cta:hover {

  color: #7F3584;
  background-color: white;

}

.search-certificate-meta>h3 {

  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 32px;

}

.search-certificate-meta>p {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  font-size: 18px;
  line-height: 1.6;

}

.search-certificate-meta>.search-certificate-subslogan {

  padding: 0;
  font-size: 16px;
  color: #7F3584;
  font-weight: bold;
  display: inline-block;
  border-radius: 6px;

}

.searchview-provider-engines-items {

  margin: 0;
  padding: 0;
  padding-bottom: 25px;
  box-sizing: border-box;
  display: block;

}

.searchview-provider-engines-item {

  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  display: block;
  text-decoration: none;

}

.searchview-provider-engines-item-inner {

  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  background-color: #7F3584;
  border: 3px solid #7F3584;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

}

.searchview-provider-engines-item-inner>h3 {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 24px;
  color: white;

}

.searchview-provider-engines-item-inner>p {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  color: white;

}

.searchview-provider-engines-item-inner>span {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 24px;
  color: white;
  position: absolute;
  right: 10px;
  top: 0;

}

.searchview-provider-engines-item:hover .searchview-provider-engines-item-inner {

  opacity: 0.9;
  cursor: pointer;
  /* border-color: white; */

}

.searchview-certificate-features-items {

  margin: 0;
  padding: 25px 0;
  padding-bottom: 5px;
  display: block;

  box-sizing: border-box;

}

.searchview-certificate-features-item {

  margin: 0;
  padding: 15px 0;
  display: block;
  float: left;
  width: 50%;

  box-sizing: border-box;

  height: 140px;

}

.searchview-certificate-features-item .fas {

  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  text-align: center;
  font-size: 36px;
  color: #7F3584;
  display: table;

  box-sizing: border-box;

}

.searchview-certificate-features-item .searchview-certificate-features-item-heading {

  margin: 0 auto;
  margin-bottom: 5px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  font-size: 14px;
  color: #111;
  display: table;

  box-sizing: border-box;

}

.searchview-certificate-features-item .searchview-certificate-features-item-text {

  margin: 0 auto;
  max-width: 75%;
  padding: 0;
  text-align: center;
  font-size: 11px;
  color: #111;
  display: table;

  line-height: 1.3;

  box-sizing: border-box;

}

.searchview-certificate-price-prefix {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

  margin-top: 25px !important;
  margin-bottom: 10px !important;
  font-size: 15px !important;

}

.searchview-certificate-price {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  background-color: #7F3584;
  color: white;

  font-size: 28px !important;
  border-radius: 6px;
  overflow: hidden;

}

.searchview-certificate-price-suffix {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

  margin-top: 5px !important;
  font-size: 15px !important;

}

.searchview-certificate-price-terms {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

  color: #111;
  margin-top: 50px !important;
  font-size: 14px !important;
  /* text-align: left !important; */

}

.searchview-certificates-types {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.searchview-certificates-type {

  margin: 0;
  padding: 0 5px;
  display: block;
  box-sizing: border-box;

  float: left;
  width: 33.3%;
  text-decoration: none;

}

.searchview-certificates-type-inner {

  margin: 0;
  padding: 10px 5px;
  display: block;
  box-sizing: border-box;

  text-decoration: none;
  border: 1px solid #7F3584;
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;

}

.searchview-certificates-type .fas {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;

  color: #7F3584;
  font-size: 24px;

  text-align: center;
  text-decoration: none;

}

.searchview-certificates-type-text {

  margin: 5px auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;

  color: #7F3584;

  text-align: center;
  text-decoration: none;
}

.searchview-certificates-type-price {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;

  color: #7F3584;
  text-align: center;
  text-decoration: none;

}

.searchview-certificates-type.active,
.searchview-certificates-type:hover {}

.searchview-certificates-type.active .searchview-certificates-type-inner,
.searchview-certificates-type:hover .searchview-certificates-type-inner {

  background-color: #7F3584;
  color: white;

}

.searchview-certificates-type.active .searchview-certificates-type-inner .fas,
.searchview-certificates-type:hover .searchview-certificates-type-inner .fas {

  color: white;

}

.searchview-certificates-type.active .searchview-certificates-type-inner .searchview-certificates-type-text,
.searchview-certificates-type:hover .searchview-certificates-type-inner .searchview-certificates-type-text {

  color: white;

}

.searchview-certificates-type.active .searchview-certificates-type-inner .searchview-certificates-type-price,
.searchview-certificates-type:hover .searchview-certificates-type-inner .searchview-certificates-type-price {

  color: white;

}

.searchview-shipper-suffx {

  margin: 0;
  margin-top: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 11px;

}

@media(max-width: 900px) {

  .searchview-provider-banner {

    margin-top: 70px;

  }

  .searchview-provider-banner-preview {

    display: none;

  }

  .searchview-provider-banner-meta {

    margin: 0 auto;
    padding: 0;
    float: none;
    width: 80%;

  }

  .searchview-provider-list {

    max-height: none !important;
    overflow-y: visible;
    padding-top: 50px;

  }

  .searchview-provider-list-item-preview img {

    width: 29%;

  }

  .details-box-preview {

    padding-bottom: 20px;

  }

  .searchview-provider-list-item-meta-name {

    font-size: 16px;

  }

  .searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-preview {

    display: none;

  }

  .searchview-provider-list-min .searchview-provider-list-item .searchview-provider-list-item-meta {

    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

  }

  .details-box-preview>img {
    max-width: 55%;
  }

  .searchview-research-btn {
    display: block;
  }

  .searchview-busy-circle {

    width: 80%;

  }

  .search-certificate-meta {

    width: 100%;
    margin-bottom: 300px;

  }

  .search-certificate-bg {

    height: auto;
    width: 100%;
    margin: 0 auto;
    /* bottom: -150px; */
    top: 360px;
    /* right: 0; */
    left: 0;
    width: 80%;

  }

  .search-certificate-meta>.search-certificate-subslogan {

    display: block;
    clear: both;

  }

}

.search-supplier-content {

  margin: 0;
  padding: 0;
  padding: 15px 25px;
  box-sizing: border-box;
  display: block;

}

.search-supplier-content p {
  font-size: 21px;
  line-height: 1.5;
}

.search-supplier-groups {

  margin: 0;
  padding: 0;
  padding-bottom: 50px;
  box-sizing: border-box;
  display: block;

}

.search-supplier-group {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

}

.search-supplier-group-meta,
.search-supplier-group-preview {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;

}

.search-supplier-group-preview img {

  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: table;
  width: 80%;

}

.search-supplier-group-meta-name {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

  font-size: 26px;
  font-weight: bold;


}

.search-supplier-group-meta-website {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

  font-size: 21px;

}

.search-supplier-group-meta-phonenumber {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

  font-size: 21px;

}

.search-supplier-group-meta-email {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;

  font-size: 21px;

}

@media(max-width: 720px) {

  .search-certificate-bg {

    top: 580px;
    width: 100%;

  }

}

@media(max-width: 600px) {

  .search-certificate-bg {

    top: 720px;
    width: 100%;

  }

  .search-supplier-group-meta,
  .search-supplier-group-preview {

    width: 100%;
  }

  .search-supplier-group-meta {
    padding-top: 30px;
    padding-left: 45px;
    padding-right: 45px;
  }

}