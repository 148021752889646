.add {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;
  background-color: #7F3584;

}

.congregate-inner {

  margin: 0;
  padding: 80px 0;
  display: block;

}

.congregate-alt {
  background-color: #FAFAFA;
}

.congregate-microchip-package {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 50%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.congregate-microchip-package-inner {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  user-select: none;
}

.congregate-microchip-package-barcode {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  user-select: none;
}

.congregate-microchip-package-barcode-text {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #111;
  outline: 0;

}

.congregate-microchip-heading {

  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 50%;
  font-size: 21px;

}

.congregate-microchip-slogan {

  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 45%;
  font-size: 13px;
  line-height: 1.5;

}

.congregate-microchip-tagline {

  margin: 0 auto;
  margin-top: 25px;
  padding: 0;
  display: table;
  text-align: center;
  color: white;
  box-sizing: border-box;
  max-width: 45%;
  font-size: 13px;
  line-height: 1.5;

}

.congregate-microchip-divider {

  margin: 25px auto;
  padding: 0;
  display: table;
  text-align: center;
  background-color: white;
  box-sizing: border-box;
  height: 1px;
  width: 10%;

}

.congregate-microchip-barcode-video {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 80%;
  max-width: 300px;
  background-color: white;
  border-radius: 3px;

}

.congregate-microchip-search {

  margin: 0 auto;
  padding: 0;
  display: table;
  width: 50%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  position: relative;

}

.congregate-microchip-search input {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 75%;
  border: 0;
  font-size: 21px;
  color: #111;
    
}

.congregate-microchip-search button {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  border: 0;
  width: 25%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 21px;
  background-color: #111;
  color: white;
    
}

.congregate-microchip-search button:hover {

  background-color: #7f3584;
  color: white;
  cursor: pointer;

}

.congregate-microchip-cta-button {

  margin: 0 auto;
  margin-top: 50px;
  padding: 15px 25px;
  display: table;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  text-decoration: none;
  background-color: #7F3584;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;

}

.congregate-microchip-cta-button:hover {

  background-color: white;
  color: #7F3584;

}

.congregate-microchip-cta-button-alt {

  background-color: white;
  color: #7F3584;

}

.congregate-microchip-cta-button-alt {


  background-color: #7F3584;
  color: white;
  font-size: 16px;

}

.congregate-microchip-take-image {

  margin: 0 auto;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 30%;
  background-color: white;
  border-radius: 1px;
  border: 1px solid whitesmoke;

}

.congregate-microchip-take-image:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.congregate-microchip-take-image-instructions {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  line-height: 1.3;
  max-width: 75%;
  text-align: center;
  font-size: 13px;

}

.congregate-microchip-take-image img {
  width: 100%;
}

.congregate-microchip-portraits {

  margin: 0 auto;
  padding: 0;
  max-width: 30%;
  box-sizing: border-box;
  display: table;

}

.congregate-microchip-portrait {
  margin: 0;
  margin-right: 15px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.congregate-microchip-portrait img {
  height: 128px;
  opacity: 1;
}

.congregate-microchip-portrait:hover img {
  opacity: 0.8;
}

.congregate-hero {

  margin: 0;
  padding: 0;
  padding-top: 40px;
  box-sizing: border-box;
  display: block;
  height: 250px;
  z-index: 1000;
  background-color: #7F3584;

}

.congregate-hero-heading {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 21px;

}

.congregate-hero-slogan {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: white;
  text-align: center;
  font-size: 16px;

}

.congregate-details-box {

  margin: 0 auto;
  margin-top: -200px;
  z-index: 5000;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 90%;
  border-radius: 6px;
  background-color: whitesmoke;
  border: 3px solid #7F3584;
  border: 1px solid white;
  overflow: hidden;
  box-shadow: 0.2em 1em 1.5em -0.5em rgba(0, 0, 0, 0.5);

}

@media(max-width: 900px) {

  .congregate-microchip-heading {
    max-width: 90%;
    font-size: 20px;
    line-height: 1.1;
  }

  .congregate-microchip-slogan, .congregate-microchip-tagline {

    max-width: 80%;
    font-size: 16px;

  }

  .congregate-microchip-package {
  
    width: 80%;
  
  }

  .congregate-inner {
    padding: 50px 0;
  }

  .congregate-microchip-search {
    width: 80%;
  }

  .congregate-microchip-take-image {

    width: 75%;

  }

}