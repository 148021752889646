.terms {

  margin: 0;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.terms-hero {

  margin: 0;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  background-color: #7F3584;

}

.terms-hero-heading {

  
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  font-size: 34px;

}

.terms-hero-slogan {

  
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  font-size: 24;
  max-width: 60%; 
  line-height: 1.6;

}

.terms-inner {

  margin: 0;
  padding: 50px 0;
  display: block;
  box-sizing: border-box;

}

.terms-inner ul {

  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;

}

.terms-heading {
  margin: 25px 0;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  color: #7F3584;
  line-height: 1.5;
}

.terms-heading svg {
  margin-right: 15px;
}

.terms-inner p {
  line-height: 1.6;
  margin-bottom: 15px;
  font-size: 18px;
  color: #666;
}


@media(max-width: 900px) {

  .terms-inner {

    padding: 50px 40px;

  }

  .terms-heading {

    font-size: 21px;

  }

  .terms-hero-slogan {

    max-width: 100%;

  }

}