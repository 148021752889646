.groups {

  margin: 0 auto;
  padding: 0;
  display: block;
  background-color: #FAFAFA;

}

.groups-inner {

  margin: 0;
  padding: 70px 0;
  padding-bottom: 40px;
  display: block;

}

.groups-controlbar {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 75%;

}

.groups-controlbar-heading {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 32px;
  color: #7F3584;

}

.groups-controlbar-slogan {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;

}

.groups-controlbar-actions {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 50%;
  float: left;

}

.groups-controlbar-meta {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 50%;
  float: left;

}

.groups-listing-items {

  margin: 0 auto;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  width: 80%;

}

.groups-listing-item {

  margin: 5px 0;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.groups-listing-item-inner {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0.2em 0.5em 1.5em -0.5em rgba(0, 0, 0, 0.5);
  position: relative;
  border-bottom: 8px solid #7F3584;
  overflow: hidden;
  border-radius: 4px;
  height: 100px;

}

.groups-listing-item-inner:hover {
  opacity: 0.8;
}

.groups-listing-item-preview {

  margin: 0;
  padding: 25px 10px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20%;
  overflow: hidden;

}

.groups-listing-item-image {

  margin: 0 auto;
  padding: 0;
  display: block;
  /* width: 40%; */
  max-height: 42px;
  box-sizing: border-box;

}

.groups-listing-item-meta {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 80%;

}

.groups-listing-item-meta-labels {

  margin: 0;
  padding: 20px 30px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.groups-listing-item-meta-labels > h3 {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  text-transform: uppercase;

}

.groups-listing-item-meta-labels > p {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 12px;

}

.groups-listing-item-meta-labels > p > img {

  display: inline-block;
  height: 12px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 2px;

}

.groups-listing-item-meta-column {

  margin: 0;
  padding: 10px 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 20%;

}

.groups-listing-item-meta-column-icon {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: #7F3584;
  font-size: 21px;

}

.groups-listing-item-meta-column-counter {

  margin: 5px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: #666;
  font-size: 21px;

}

.groups-listing-item-meta-column-label {

  margin: 0 auto;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: gray;
  font-size: 9px;
  max-width: 50%;
  line-height: 1.3;

}

.groups-cta {

  margin: 0;
  padding: 15px 25px;
  display: block;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 6px;
  background-color: white;
  text-decoration: none;
  color: #7F3584;
  font-size: 17px;
  cursor: pointer;
  border: 1px solid #7F3584;

  background-color: #7F3584;
  color: white;

}

.groups-cta:hover {

  background-color: white;
  border: 1px solid #7F3584;
  color: #7F3584;

}

.groups-controlbar-cta {
  
  float: right;
  font-size: 24px;

}

.groups-items-blank {

  margin: 0;
  padding: 0;
  padding-bottom: 80px;
  display: block;
  box-sizing: border-box;

}

.groups-items-blank .groups-items-blank-cta {
  margin: 25px auto;
  display: table;
}

.groups-items-blank .fas {

  margin: 0;
  margin-top: 80px;
  margin-bottom: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 164px;
  color: #7F3584;

}

.groups-items-blank p {

  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  max-width: 60%;
  font-size: 21px;
  line-height: 1.4;

}

@media(max-width: 900px) {

  .groups-listing-items {

    margin: 0 auto;
    width: 80%;

  }

  .groups-listing-item-inner {

    padding-right: 25px;

  }

  .groups-listing-item-meta {

    width: 100%;

  }

  .groups-listing-item-preview {

    display: none;

  }

  .groups-listing-item-meta-labels > p > img {

    display: none;

  }

  .groups-listing-item-meta-labels > h3 {

    margin-top: 7px;
    font-size: 16px;

  }

  .groups-listing-item-meta-labels > p {

    font-size: 9px;

  }

  .groups-listing-item-meta-column-label {

    max-width: 75%;

  }

}