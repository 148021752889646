.register {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.register-inner {

  margin: 0;
  padding: 40px;
  padding-bottom: 80px;
  display: block;
  box-sizing: border-box;

}

.register-wrapper {

  margin: 0;
  padding: 120px 0;
  padding-bottom: 150px;
  display: block;
  box-sizing: border-box;

}

.register-form {

  margin: 0 auto;
  padding: 25px 20px;
  display: block;
  box-sizing: border-box;
  width: 50%;
  overflow: hidden;

}

.register-visual {

  margin: 0;
  padding: 0;
  padding-top: 40px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.register-visual .register-icon {

  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  text-align: center;
  font-size: 82px;
  color: white;

}

.register-visual .register-heading {

  margin: 0;
  padding: 0;
  display: block;
  text-align: center;
  color: white;
  font-size: 32px;

}

.register-visual .register-slogan {

  margin: 15px auto;
  padding: 0;
  max-width: 60%;
  display: block;
  text-align: center;
  color: white;
  line-height: 1.25;
  font-size: 14px;

}

.register-form-control {

  margin: 0;
  padding: 8px 15px;
  display: block;
  clear: both;

}

.register-form-control label {

  margin: 0;
  padding: 5px 0;
  padding-bottom: 15px;
  display: block;
  box-sizing: border-box;
  font-size: 17px;
  text-transform: uppercase;
  color: #666;
  width: 100%;

}

.register-form-control input {

  margin: 0;
  padding: 15px 15px;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  overflow: hidden;
  border: 1px solid whitesmoke;
  border-radius: 4px;

}

.register-form-control input:hover {

  border-color: gray;

}

.register-form-control p {

  margin: 0;
  margin-top: 5px;
  padding: 0;
  display: block;
  float: left;
  line-height: 1.2;
  max-width: 70%;
  font-size: 11px;
  text-decoration: none;

}

.register-form-control a {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  float: left;
  font-size: 14px;
  text-decoration: none;

}

.register-form-control a:hover {

  text-decoration: underline;

}

.register-form-control button {

  margin: 0;
  padding: 5px 10px;
  display: block;
  float: right;
  background-color: #53AE82;
  border: 1px solid #53AE82;
  font-size: 18px;
  color: white;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;

}

.register-form-control button:hover {

  background-color: white;
  color: #53AE82;

}